import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        secondary: {
        main: "#707070",
        contrastText: "#fff",
        },
    },
    typography: {
        allVariants: {
        fontFamily: ["'Open Sans'", "sans-serif"].join(',')
        }
    },
});

