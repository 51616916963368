import { theme } from '../../theme';
const styles = (theme) => ({
    loginContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: 24
    },
    loginForm: {
        width: '100%',
        maxWidth: 400,
        minHeight: 150,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    logo: {
        width: 80,
        height: 100,
        marginBottom: 16
    },
    welcomeText: {
        color: '#323232',
        fontSize: 22,
        fontWeight: 600,
        lineHeight: 1.2,
        marginBottom: 16,
    },
    helperText: {
        color: '#646464',
        fontSize: 14,
        marginBottom: 16,
    },
    textField: {
        backgroundColor: '#FFFFFF',
        width: '90%',
        marginTop: 16,
    },
    textFieldInput: {
        fontSize: 14
    },
    signIn: {
        backgroundColor: '#FF7377',
        textTransform: 'capitalize',
        color: '#FFFFFF',
        width: '90%',
        paddingTop: 12,
        paddingBottom: 12,
        marginTop: 16,
        '&:hover': {
            backgroundColor: '#FF7377'
        }
    },
    forgotBox: {
        width: '90%',
        paddingTop: 8,
        paddingBottom: 8,
        textAlign: 'right'
    },
    forgotPass: {
        fontSize: 12,
        marginTop: 8,
        color: '#646464',
        cursor: 'pointer',
    }
});

export default styles;