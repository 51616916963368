import React, { useEffect, useState } from 'react';
import useClasses from '../../hooks/useClasses';
import { Box, Button, Typography } from '@mui/material';
import styles from './Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';
import { routes } from '../../router/constants'
import { routes as apiRoutes } from '../../api/constants';
import { sendPOST } from '../../api';

function RegistrationCompleteStep()
{
    const classes = useClasses(styles);
    const [complete, setComplete] = useState("UNINITIATED");
    const [register_id, setRegisterID] = useState(null);
    const register = useSelector((state) => state.register);
    const session = useSelector((state) => state.session);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const goToSummaray = () => {
      navigate(routes.SUMMARY);
    };

    useEffect(() => {
      if (complete === "UNINITIATED") {
        console.log(register);
        setComplete("PROCESSING");
        sendPOST(
          apiRoutes.submitRegistration,
          register,
          dispatch,
          { 'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}` }
        ).then(data => {
          if (data.status) {
            setComplete("SUCCESS");
            setRegisterID(data.registration_id);
            console.log(data);            
          } else {
            setComplete("FAILED");
            console.log(data);
          }
        });
      }
    }, []);

    useEffect(() => {
      if (complete == 'SUCCESS' && register_id != null) {
        sendPOST(
          apiRoutes.sendWhatsappConfirmation,
          { id: register_id },
          dispatch,
          { 'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}` }
        ).then(data => {
          console.log(data);
          if (data.status) {
            setComplete("EMAIL-SENT");
          } else {
            setComplete("EMAIL-FAILED");
          }
        });
      }
    }, [complete, register_id]);

    return (
        <Box pt={2} className={classes.formContainer}>
          {
            complete === "PROCESSING" && (
              <Typography variant='body2' component='div' className={classes.stepDesc}>{ "Please wait, processing the registration. It might take a moment..." }</Typography>
            )
          }
          {
            complete === "SUCCESS" && (
              <Typography variant='body2' component='div' className={classes.stepDesc}>{ "Registration submitted successfully. Sending whatsapp confirmation, please wait..." }</Typography>
            )
          }
          {
            complete === "EMAIL-SENT" && (
              <>
                <Typography variant='body2' component='div' className={classes.stepDesc} mb={2}>{ "Registration was successful!. Please consider making a donation towards Shatakoti Gayatri Havanam and avail tax benefits." }</Typography>
                <a href='https://payu.in/web/90BEFCBA9B129676A09AD2484CB7ACAB' target="_blank"><Button variant='filled' className={classes.summaryButton} style={{ background: '#1976d2', marginRight: 8 }} onClick={() => goToSummaray()}>Donate to Shatakoti</Button></a>
                <Button variant='filled' className={classes.summaryButton} onClick={() => goToSummaray()}>View Summary</Button>                
              </>              
            )
          }
          {
            complete === "FAILED" && (
              <Typography variant='body2' component='div' className={classes.stepDesc}>{ "Registration failed. Ivalid input provided." }</Typography>
            )
          }
          {
            complete === "EMAIL-FAILED" && (
              <>
                <Typography variant='body2' component='div' className={classes.stepDesc}>{ "Registration was successfully completed, but could not send whatsapp confirmation to you. You can request for whatsapp confirmation from the Summary page by clicking on the 'Share via Email' button." }</Typography>
                <NavLink to='https://payu.in/web/90BEFCBA9B129676A09AD2484CB7ACAB'><Button variant='filled' className={classes.summaryButton} style={{ background: '#1976d2', marginRight: 8 }} onClick={() => goToSummaray()}>Donate to Shatakoti</Button></NavLink>
                <Button variant='filled' className={classes.summaryButton} onClick={() => goToSummaray()}>View Summary</Button>
              </>              
            )
          }
        </Box>    
    );
}

export default RegistrationCompleteStep;
