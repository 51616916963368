import React from 'react';
import RegistrationContainer from './RegistrationContainer';

const Registration = () => {
    return <RegistrationContainer />;
};

export const memberDetailsObject = (id,dispatch) => {
    dispatch(setBusy({ busy: true }));
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: id})
    };
    return fetch(routes.memberDetailsApi, requestOptions).then(async(response) => {
        dispatch(setBusy({ busy: false }));
        if (response.ok) {
            let data = await response.json();
            if (data.status) {
                return data;
            } else {
                dispatch(setToast({ message: data.message, type: 'error' }));
                return data;
            }
        }
        throw response;
    });
};

export default Registration;