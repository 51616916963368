import { createSlice } from "@reduxjs/toolkit";
import { decodeToken } from "react-jwt";

export const session = createSlice({
    name: 'session',
    initialState: {
        id: null,
        firstName: null,
        lastName: null,
        gothram: null,
        email: null,
        contact: null,
        token: null,
        event: null,
        is_member: false,
    },
    reducers: {
        create: (state, action) => {
            const token = decodeToken(action.payload.token);
            state.token = action.payload.token;
            state.id = token.data.id;
            state.firstName = token.data.firstName;
            state.lastName = token.data.lastName;
            state.gothram = token.data.gothram;
            state.contact = token.data.contact;
            state.email = token.data.email;
            state.event = action.payload.event;
            state.is_member = action.payload.is_member;
        },
        setEvent: (state, action) => {
            state.event = action.payload.event;
        },
        destroy: (state) => {
            state.id = null;
            state.firstName = null;
            state.lastName = null;
            state.gothram = null;
            state.token = null;
            state.email = null;
            state.contact = null;
            state.event = null;
            state.is_member = false;
        }
    }
});

export const { create, destroy, setEvent } = session.actions;
export default session.reducer;