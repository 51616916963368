import { createSlice } from "@reduxjs/toolkit";

export const app = createSlice({
   name: 'app' ,
   initialState: {
    busy: false,
    toast: {
        message: '',
        type: ''
    }
   },
   reducers: {
    setBusy: (state, action) => {
        state.busy = action.payload.busy;
    },
    setToast: (state, action) => {
        state.toast = {
            message: action.payload.message,
            type: action.payload.type
        };
    },
    removeToast: (state) => {
        state.toast = {
            message: '',
            type: ''
        };
    }
   }
});

export const { setBusy, setToast, removeToast } = app.actions;
export default app.reducer;