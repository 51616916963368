import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useDispatch } from 'react-redux';

const SimpleSnackbar = (props) => {
    const { type, message, removeToast } = props;
    const [open, setOpen] = React.useState(true);
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
        dispatch(removeToast());
    }

    return (
        <Snackbar 
            open={open} 
            autoHideDuration={4000} 
            onClose={handleClose} 
            anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
        >
            <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                { message }
            </Alert>
        </Snackbar>
    );
};

export default SimpleSnackbar;