import React, { useEffect, useState } from 'react';
import useClasses from '../../hooks/useClasses';
import { Box, TextField, InputAdornment, Grid, Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert } from '@mui/material';
import MuiPhoneNumber from "material-ui-phone-number";
import styles from './Styles';
import { useDispatch, useSelector } from 'react-redux';
import { sendPOST } from "../../api";
import { imageURL, routes as apiRoutes } from '../../api/constants';
import isEmail from 'validator/lib/isEmail';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import { setContact, setEmail, setNoOfParticipants, setArrivalDate, setDepartDate, setParticipants, destroy as destroyRegistration } from './slice';
import { destroy as destroyMetadata } from './metadata';
import { destroy } from '../Login/slice';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../router/constants';
import { setToast } from '../Common/slice';

function MemberDetailsStep({ setSteps }) {
    const classes = useClasses(styles);
    const register = useSelector((state) => state.register);
    const session = useSelector((state)=>state.session);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const getMinDate = (date) => {
        let today = new Date();
        let min_date = new Date(session.event.start_date);
        min_date.setDate(min_date.getDate() - 2);
        if (today > min_date) {
            min_date = today
        }
        const min_year = min_date.getFullYear();
        let min_month = min_date.getMonth() + 1;
        let min_day = min_date.getDate();
        if (min_day < 10) min_day = '0' + min_day;
        if (min_month < 10) min_month = '0' + min_month;
        return min_year + "-" + min_month + "-" + min_day;
    }

    const getMaxDate = (date) => {
        let max_date = new Date(session.event.end_date);
        max_date.setDate(max_date.getDate() + 1);
        const max_year = max_date.getFullYear();
        let max_month = max_date.getMonth() + 1;
        let max_day = max_date.getDate();
        if (max_day < 10) max_day = '0' + max_day;
        if (max_month < 10) max_month = '0' + max_month;
        return max_year + "-" + max_month + "-" + max_day;
    }

    const handleNoParticipants = (e) => {
        let participant_count = parseInt(e.target.value);
        console.log(register);
        participant_count = isNaN(participant_count) ? 0 : participant_count;
        let steps = [
            {
                key: 'enrolment',
                label: 'Enrollment Details',
                desc: 'Please provide enrollment details below.',
                data: null,
            }
        ];
        let participants = [];
        for (var i=1; i<=participant_count+1; i++) {
            if (i <= register.participants.length) {
                participants.push({
                    participant_id: register.participants[i-1].participant_id,
                    member_id: register.participants[i-1].member_id,
                    type: register.participants[i-1].type,
                    first_name: register.participants[i-1].first_name,
                    last_name: register.participants[i-1].last_name,
                    gothram: register.participants[i-1].gothram,
                    gender: register.participants[i-1].gender,
                    age: register.participants[i-1].age,
                    slots: register.participants[i-1].slots,
                    food: register.participants[i-1].food,
                    is_srisevak: register.participants[i-1].is_srisevak
                });
            } else {
                participants.push({
                    participant_id: null,
                    member_id: null,
                    type: "VISITOR",
                    first_name: "",
                    last_name: "",
                    gothram: "",
                    gender: "M",
                    age: "",
                    slots: [],
                    food: [],
                    is_srisevak: "NO"
                });
            }            
            steps.push({
                key: 'participant',
                label: i === 1 ? 'Primary Participant' : `Co-Participant ${i-1}`,
                desc: i === 1 ? 'Please provide / update your personal details below and click NEXT' : `Please provide details of co-participant ${i-1}`,
                data: i-1
            });
        }
        steps.push({
            key: 'complete',
            label: 'Registration Status  🙏',
            desc: '',
            data: null,
        });
        dispatch(setParticipants({ participants: participants }));
        setSteps(steps);
    }

    const deleteRegistration = () => {
        sendPOST(
            apiRoutes.deleteRegistration,
            { registration_id: register.registration_id },
            dispatch,
            { 'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}`  }
        ).then(data => {
            console.log(data);
            if (data.status) {
                dispatch(destroy());
                dispatch(destroyRegistration());
                dispatch(destroyMetadata());
                dispatch(setToast({ message: 'Registration deleted successfully. Logging you out...', type: 'success' }));
                navigate(routes.LOGIN);
            }
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        handleNoParticipants({ target: { value: register.no_of_participants } });
    }, [register.no_of_participants]);

    return (
        <Box pt={2} className={classes.formContainer}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        label="Enrollment By"
                        value={`${register.name} (ID# ${register.poc_id})`}
                        required
                        disabled={true}
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Avatar
                                        alt="Member Image"
                                        src={imageURL + register.poc_id}
                                        sx={{ width: 24, height: 24 }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        className={classes.inputField}
                    />
                </Grid>
                <Alert severity="info" style={{ marginTop: 16 }}>
                    If you wish to be considered as Srisevak and do seva, you are expected to report at Ayodhya two days before the event starts and leave one day after the event ends i.e arrive by 25th July 2024 and leave on or after 1st August 2024. This is to ensure proper planning and coordination among the Srisevaks.
                </Alert>
                <Grid item xs={6}>                    
                    <TextField
                        label="Arrival at Venue"
                        required
                        type="date"
                        helperText={`Event begins on ${(new Date(session.event.start_date)).toDateString()}`}
                        value={register.arrival_date}
                        className={classes.inputField}
                        variant='outlined'
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                            inputProps: { min: getMinDate(session.event.start_date), max: session.event.end_date }
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => dispatch(setArrivalDate({ arrival_date: e.target.value })) }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Departure from Venue"
                        required
                        type="date"
                        helperText={`Event ends on ${(new Date(session.event.end_date)).toDateString()}`}
                        value={register.depart_date}
                        className={classes.inputField}
                        variant='outlined'
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                            inputProps: { min: session.event.start_date, max: getMaxDate(session.event.end_date) }
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => dispatch(setDepartDate({ depart_date: e.target.value })) }
                    />
                </Grid>
                <Grid item xs={6}>
                    <MuiPhoneNumber
                        label="Contact Number"
                        required
                        fullWidth
                        value={register.contact}
                        defaultCountry={"in"}
                        variant='outlined'
                        InputProps={{
                            classes: { input: classes.textFieldInput }
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className={classes.inputField}
                        onChange={(e) => dispatch(setContact({ contact: e })) }
                    />    
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Email"
                        error={register.email !== "" && register.email !== undefined && register.email !== null && !isEmail(register.email)}
                        required
                        type="email"
                        value={register.email}
                        helperText={register.email !== "" && register.email !== undefined && register.email !== null && !isEmail(register.email) ? "Invalid email" : ''}
                        className={classes.inputField}
                        variant='outlined'
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => dispatch(setEmail({ email: e.target.value })) }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="No. of Co-Participants"
                        required
                        type="number"
                        value={register.no_of_participants}
                        helperText="Total participants excluding yourself"
                        className={classes.inputField}
                        variant='outlined'
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                            inputProps: { min: 0, max: 10 }
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => dispatch(setNoOfParticipants({ no_of_participants: e.target.value }))}
                        onBlur={handleNoParticipants}
                        disabled={register.hasOwnProperty('registration_id') && register.registration_id != null ? true : false}
                    />
                </Grid>
                {
                    register.hasOwnProperty('registration_id') && register.registration_id != null &&
                    <>
                        <Grid item xs={12} mt={2}>
                            <Button  startIcon={<PersonAddAltIcon />} variant="outlined" onClick={() => dispatch(setNoOfParticipants({ no_of_participants: parseInt(register.no_of_participants) + 1 }))}>Add Participant</Button>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Button  startIcon={<DeleteIcon />} variant="outlined" onClick={handleOpen} color='error'>Delete Registration</Button>
                        </Grid>
                    </>
                }
                {
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Delete Registration
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to proceed with the delete action? By proceeding your complete registration will be deleted including your co-participants and slots. Once deleted you will be logged out automatically.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose}>Disagree</Button>
                        <Button onClick={() => dispatch(deleteRegistration())} autoFocus>
                            Agree
                        </Button>
                        </DialogActions>
                    </Dialog>
                }
            </Grid>
        </Box>    
    );
  }
  
  export default MemberDetailsStep;