import { Box, Card, Divider, Typography, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useClasses from '../../hooks/useClasses';
import styles from './Styles';

const FoodPicker = ({ index, date, foods, addFood, removeFood }) => {
    const classes = useClasses(styles);
    const register = useSelector((state)=>state.register);
    let date_split = date.split("-");
    const [breakfast, setBreakfast] = useState(false); 
    const [lunch, setLunch] = useState(false); 
    const [dinner, setDinner] = useState(false);

    useEffect(() => {
        setBreakfast(register.participants[index].food.includes(foods.BREAKFAST.tag_id));
        setLunch(register.participants[index].food.includes(foods.LUNCH.tag_id));
        setDinner(register.participants[index].food.includes(foods.DINNER.tag_id));
    }, [index]);

    const addZero = (value) => {
        return value > 9 ? value : "0" + value.toString();
    }

    useEffect(() => {
        const tag_id = foods.BREAKFAST.tag_id;
        if (breakfast) {
            addFood(tag_id);
        } else {
            removeFood(tag_id);
        }
    }, [breakfast]);

    useEffect(() => {
        const tag_id = foods.LUNCH.tag_id;
        if (lunch) {
            addFood(tag_id);
        } else {
            removeFood(tag_id);
        }
    }, [lunch]);

    useEffect(() => {
        const tag_id = foods.DINNER.tag_id;
        if (dinner) {
            addFood(tag_id);
        } else {
            removeFood(tag_id);
        }
    }, [dinner]);
    
    const printMonth = (value) => {
        return {
            1 : 'Jan',
            2 : 'Feb',
            3 : 'Mar',
            4 : 'Apr',
            5 : 'May',
            6 : 'June',
            7 : 'July',
            8 : 'Aug',
            9 : 'Sept',
            10: 'Oct',
            11: 'Nov',
            12: 'Dec'
        } [value]
    }

    return (
        <Card classes={{root:classes.foodContainer}}>
            <Box className={classes.foodSectionHeader}><Typography variant='h6' className={classes.foodSectionTitle}>Food on {`${date_split[2]} ${printMonth(parseInt(date_split[1]))} ${date_split[0]}`}</Typography></Box>
            <Grid container flexItem>
                <Box classes={{root:classes.foodSectionItem}} className={breakfast ? classes.foodSelectedItem : classes.foodSectionItem} onClick={() => setBreakfast(!breakfast)}>
                    <Typography variant='body2' component="div" className={breakfast ? classes.foodSelectedLabel : classes.foodItemLabel}>BREAKFAST</Typography>
                    <Typography variant='body2' component="div" classes={{ root: classes.foodItemInfo }} className={breakfast ? classes.foodSelectedInfo : classes.foodItemInfo}>{ breakfast ? 'Selected' : 'Click to choose' }</Typography>
                </Box>
                <Divider orientation="vertical" flexItem/>
                <Box classes={{root:classes.foodSectionItem}} className={lunch ? classes.foodSelectedItem : classes.foodSectionItem} onClick={() => setLunch(!lunch)}>
                    <Typography variant='body2' component="div" className={lunch ? classes.foodSelectedLabel : classes.foodItemLabel}>LUNCH</Typography>
                    <Typography variant='body2' component="div" classes={{ root: classes.foodItemInfo }} className={lunch ? classes.foodSelectedInfo : classes.foodItemInfo}>{ lunch ? 'Selected' : 'Click to choose' }</Typography>
                </Box>
                <Divider orientation="vertical" flexItem/>
                <Box classes={{root:classes.foodSectionItem}} className={dinner ? classes.foodSelectedItem : classes.foodSectionItem} onClick={() => setDinner(!dinner)}>
                    <Typography variant='body2' component="div" className={dinner ? classes.foodSelectedLabel : classes.foodItemLabel}>DINNER</Typography>
                    <Typography variant='body2' component="div" classes={{ root: classes.foodItemInfo }} className={dinner ? classes.foodSelectedInfo : classes.foodItemInfo}>{ dinner ? 'Selected' : 'Click to choose' }</Typography>
                </Box>
            </Grid>
        </Card>
    );
};

export default FoodPicker;