import React from 'react';
import { Grid, Typography, FormControl, InputLabel, Select, MenuItem, useTheme, useMediaQuery } from '@mui/material';
import useClasses from '../../hooks/useClasses';
import styles from './Styles';
import SummaryOverview from './SummaryOverview';
import Participants from './Participants';


const SummaryView = () => {
    const classes = useClasses(styles);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [ view, setView ] = React.useState('overview');
    const [lang, setLang] = React.useState('te');
    
    return (
        <Grid container spacing={isMobile ? 4 : 8} className={classes.summaryView}>
            <Grid item xs={12} md={4}>
                {
                    view === 'overview' ? <SummaryOverview onExpand={() => setView('participant')}/> : <Participants onCollapse={() => setView('overview')} />
                }
                
            </Grid>
            <Grid item xs={12} md={8}>
                <FormControl xs={6}>
                    <InputLabel id="demo-simple-select-label">Language</InputLabel>
                    <Select
                        value={lang}
                        label="Language"
                        onChange={(e) => setLang(e.target.value)}
                    >
                        <MenuItem value={'te'}>Telugu</MenuItem>
                        <MenuItem value={'en'}>English</MenuItem>
                    </Select>
                </FormControl>
                {
                    lang == 'en' && 
                    <Grid item xs={12} md={8} mt={2}>
                        <Typography variant='h6' component="div" className={classes.instructionsTitle}>Important Guidelines to Participants - Do's & Dont's</Typography>
                        <Typography variant='body2' component="p" className={classes.instructions}>
                            <strong>{"Do's"}</strong>
                        </Typography>
                        <ul className={classes.instructionList} type="a">
                            <li>To Participate in Havanam , Japathas must register their name first ,  perform at least 100000 Japam and relevant Tarpanam and enter in the Organisation App ( in KCDT APP ) . Every decision is based on the App. There will be no relaxation in this policy. </li>
                            <li>During ALL five days , to enter into the premises, Japathas & visitors should wear only traditional attire (Panchakattu & Uttariyamu).</li>
                            <li>We are fortunate to be a part of a very exciting spiritual event, so let's get ready and support the Karyakrtaas as much as we can.</li>
                            <li>Rules and Regulations of the Organisation must be followed. On Forehead [Nuduta] Bhasmadharana and Tilakadharana are mandatory.</li>
                            <li>Time management and discipline are very essentials for the smooth running of the event.</li>                            
                            <li>Women should also be in traditional attire only. During Kumkum Puja & at while rendering food distribution services in Dining Hall all the married women have to wear only ' traditional type sarees'. All ladies are requested to use only  kumkum  on the forehead (kindly avoid stickers of different colours) Unmarried women should also wear only  sarees . Make sure that the girls are dressed in traditional attire with Parikini Jakettu. Jeans Pants & T-shirts and other western type costumes are Strictly prohibited.</li>
                            <li>Women should also bring Achamana Patraset for Kumkum Puja. Use of plastic glasses are prohibited.</li>
                            <li>Environmental cleanliness is the goal of all of us. Please cooperate with us.</li>
                            <li>'Your health is in your hands' so you may not get all kinds of medicines in and around the event place according to your need so kindly bring necessary medicines which are required for you in sufficient quantity.  You can bring cash according to your need.</li>
                            <li>On entering the program premises, everyone should report at the Registration Desk.</li>
                            <li>Program details and changes will be announced from time to time through Mike based on this information kindly implement the required action promptly.</li>
                            <li>Your luggage is your responsibility.</li>
                            <li>Apart from havanam, kumkumarchana, lunch & snacks will be served as per registration in the App only. Therefore, apart from the members, the visitors are  also required to register in the App.</li>
                        </ul>

                        <Typography variant='body2' component="p" className={classes.instructions}>
                            <strong>{"Don'ts"}</strong>
                        </Typography>
                        <ul className={classes.instructionList} type="a">
                            <li>Do not bring valuables & jewellery.</li>
                            <li>No shoes/ chappals are  allowed in the venue. leave them out at designated place .</li>
                            <li>We request you to cooperate in conducting the program peacefully, without arguments and quarrels with anyone. Violation of discipline will not be tolerated.</li>
                            <li>'Less Luggage - More Comfort' so do not bring more luggage.</li>
                            <li>Smoking is prohibited in Ayodhya, so it will be strictly enforced in our premises.</li>
                            <li>Food & Water are essentials so kindly avoid wasting.</li>
                            <li>Glass of water, tea and coffee should be disposed only in designated place without throwing it everywhere.</li>
                            <li>If you have the opportunity, take up the service as Kaaryakartaa , Don't look to someone else to serve you. YOU ARE WELCOME to render service. please contact our " HELP DESK" at venue or trust phone numbers 9494877070, 9494877171.</li>                            
                        </ul>
                    </Grid>
                }
                {
                    lang == 'te' && 
                    <Grid item xs={12} md={8} mt={2}>
                        <Typography variant='h6' component="div" className={classes.instructionsTitle}>ముఖ్యమైన గమనికలు - Do's & Dont's</Typography>
                        <Typography variant='body2' component="p" className={classes.instructions}>
                            <strong>{"చేయవలసినవి -- Do's"}</strong>
                        </Typography>
                        <ul className={classes.instructionList} type="a">
                            <li>హవనమునకు అమొదించుటకు (యాగశాల ప్రవెశమునకు) జపితలు ముందుగా పేరు నమొదు చెసుకుని, కనీసముగా 100000 జపము తత్దశాంశము తర్పనము పూర్తిచెసి సంస్థ యాప్ లొ పొందుపరచుట తప్పనిసరి. సంస్త యాప్ ఆధారముగానె ప్రతి నిర్నయము వుంటుంది. ఈ విధానములొ ఎటువంటీ సడలింపు వుండదు.</li>
                            <li>యజ్ఞప్రాంగణంలో ఐదురోజులు, జపతులేకాక సందర్శకులు కూడా సాంప్రదాయ వస్త్రధారణలోనే (పంచకట్టు, ఉత్తరీయము) ఉండాలి.</li>
                            <li>మనము ఒక అత్యధ్భుత ఆధ్యాత్మిక కార్యక్రమానికి వచ్చాము ఇందులో పాల్గొనడం అదృష్టం , కనుక,  ఉన్నంతలో  సర్దుకుని సహకరించమనవి.  </li>
                            <li>సంస్థ నియమ నిబంధనలు విధిగా పాటించాలి. నుదుట భస్మ, తిలకధారణ తప్పనిసరి.</li>
                            <li>కార్యక్రమము సక్రమంగా జరుగుటకు సమయపాలన, క్రమశిక్షణ చాలా అవసరము.</li>                            
                            <li>స్త్రీలు కూడా ,విధిగా సాంప్రదాయ వస్త్రధారణలోనే ఉండాలి. కుంకుమపూజ, వడ్డన సమయములో, గృహిణిలందరూ 'గోచీపోసి చీరకట్టు' లోనే అనుమతి. నుదుట కుంకుమ ధరించాలి (వివిధ రంగుల స్టిక్కర్లు వద్దని మనవి) అవివాహిత మహిళలుకూడా చీరకట్టులోనే ఉండాలి. బాలికలు పరికిణీ జాకట్టుతో సాంప్రదాయ వస్త్ర ధారణతో ఉండేలా చూసుకోండి . జీన్స్ పాంట్స్ , టీ షర్ట్స్ నిషిద్ధం</li>
                            <li>మహిళలుకూడా కుంకుమపూజ కొరకు ఆచమనపాత్రసెట్ తీసుకుని రావలెను. ప్లాస్టిక్ గ్లాసుల వినియోగం నిషిద్ధముకూడా</li>
                            <li>పరిసరాల పరిశుభ్రత మన అందరి లక్ష్యం .  దయ చేసి సహకరించండి.</li>
                            <li>అవసరమైన మందులు తెచ్చుకుని రాగలరు. 'మీ ఆరోగ్యము మీచేతులలోనే'. మీ అవసరానికితగ్గట్టు అన్నిరకాల మందులు దొరకకపోవచ్చు.మీ అవసరానికి అనుగునంగా మాత్రం నగదు తీసుకుని రాగలరు.</li>
                            <li>సమయం చాల విలువైనది , ప్రతి నిముషం ఏదో ఒక కార్యక్రమం ఏర్పాటు చేసాం . పాల్గొనండి . అనవసర విషయాలు చర్చించి సమయం వృధా చేసుకోకండి  . నిశ్శబ్దం పాటించండి.</li>
                            <li>కార్యక్రమం ప్రాంగణమున ప్రవేశించగానే, Registration Counter నందు ప్రతి ఒక్కరు పేరు నమోదు చేసుకునవలెను.</li>
                            <li>కార్యక్రమ వివరములు, మార్పులు ఎప్పటికప్పుడు మైకునందు ప్రకటణచేయుచుందురు, వాటినాలకించి తదనుగుణంగా త్వరత్వరగా నడచుకొనగలరు.</li>
                            <li>ఎవరి సామానుకు వారిదే భాద్యత.</li>
                            <li>హవనమునకే కాకుండా కుంకుమార్చన మరియు భోజన పలహారాదులు కూడా సంస్థ App నందు నమోదు ప్రకారమే అనుమతించబడును. కావునా సభ్యులే కాకుండా సందర్శకులు కూడా App లో నమోదు విషయములో సహకరించమనవి.  </li>
                        </ul>

                        <Typography variant='body2' component="p" className={classes.instructions}>
                            <strong>{"చేయకూడనివి - Dont's"}</strong>
                        </Typography>
                        <ul className={classes.instructionList} type="a">
                            <li>విలువైన వస్తువులు, ఆభరణములు, తీసుకుని రావలదు.</li>
                            <li>కార్యక్రమప్రాంగణంలో పాదరక్షలతో అనుమతి లేదు.  </li>
                            <li>దయచేసి ఎవరితోనూ వాదప్రతివాదాలు, గొడవలు లేకుండా, ప్రశాంతంగా కార్యక్రమం జరుగుటకు సహకరించ మనవి. క్రమశిక్షణ  ఉల్లంఘన సహించ బడదు . అట్టి వ్యక్తులను  సంస్థ సభ్యత్వం నుండి తొలిగించడం తప్పనిసరి.</li>
                            <li>'Less Luggage - More Comfort' అవసరానికి మించి సామానులు తీసుకుని రావలదు.</li>
                            <li>అయోధ్యలో ధూమపానం నిషిద్ధం, అందునా మన కార్యక్రమప్రాంగణంలో నిక్కచ్చిగా అమలుజరుగును.</li>
                            <li>ఆహారము, మంచి నీరు ఇతరములు వృధాకాకుండా సహకరించమనవి.</li>
                            <li>మంచినీళ్ల, టీ కాఫీ గ్లాసులు ఎక్కడకక్కడ పాడవేయకుండా, నియమిత ప్రదేశములోనే వేయవలెను.</li>
                            <li>అవకాసం ఉంటె కార్యకర్తగా సేవకు మీరు ఉపక్రమించండి . వేరొకరు మీకు సేవ చేయాలని చూడకండి. వివరాలకు వెదిక వద్ద "HELP DESK" ను సంప్రదించండి లెదా సంస్థ phone 9494877070, 9494877171 లను సంప్రదించండి .</li>                            
                        </ul>
                    </Grid>
                }
            </Grid>            
        </Grid>
    );
};

export default SummaryView;