import { createSlice } from "@reduxjs/toolkit";

export const metadata = createSlice({
    name: 'metadata',
    initialState: {
        gothrams: [],
        slots: [],
        food: [],
        parayana: [],
    },
    reducers: {
        setGothrams: (state, action) => {
            state.gothrams = action.payload.gothrams;
        },
        setSlots: (state, action) => {
            state.slots = action.payload.slots;
        },
        setParayana: (state, action) => {
            let parayanas = {};
            action.payload.parayana.map((item) => {
                if (parayanas.hasOwnProperty(item.date)) {
                    parayanas[item.date].push(item);
                } else {
                    parayanas[item.date] = [item];
                }
            });
            state.parayana = parayanas;
        },
        setFoods: (state, action) => {
            let foods = {};
            action.payload.food.map((item, idx) => {
                if (foods.hasOwnProperty(item.date)) {
                    foods[item.date][item.tag_label] = item;
                } else {
                    foods[item.date] = {};
                    foods[item.date][item.tag_label] = item;
                }
            });
            state.food = foods;
        },
        destroy: (state) => {
            state.gothrams = null;
            state.food = null;
            state.slots = null;
        }
    }
});

export const { setGothrams, setSlots, setFoods, setParayana, destroy } = metadata.actions;
export default metadata.reducer;