import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { routes } from "./constants";

const ProtectedRoute = ({ children }) => {
  const { token } = useSelector((state) => state.session);
  return token == null ? <Navigate to={routes.LOGIN} /> : children;
};

export default ProtectedRoute;
