import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { routes } from './constants';
import Home from '../components/Home';
import Login from '../components/Login';
import NonMemberLogin from '../components/NonMemberLogin';
import Details from '../components/Details';
import ForgotPassword from '../components/ForgotPassword';
import Registration from '../components/Registration';
import Summary from '../components/Summary';
import Spot from '../components/Spot';

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path={routes.HOME}
                    element={<Navigate to={routes.LOGIN} replace />}
                />
                <Route path={routes.LOGIN} element={<Login />}/>
                <Route path={routes.NON_MEMBER_LOGIN} element={<NonMemberLogin />} />
                <Route path={routes.FORGOT_PASS} element={<ForgotPassword />}/>
                <Route path={routes.DETAILS} element={<Details />}/>
                <Route path={routes.SPOT} element={
                    <ProtectedRoute>
                        <Spot />
                    </ProtectedRoute>
                }/>
                <Route 
                    path={routes.REGISTER}
                    element={
                        <ProtectedRoute>
                            <Registration />
                        </ProtectedRoute>
                    } 
                />
                <Route
                    path={routes.SUMMARY}
                    element={
                        <ProtectedRoute>
                            <Summary />
                        </ProtectedRoute>
                    }
                />                
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;