import React from 'react';
import { Grid, Box, List, ListItem, Divider, ListItemText, ListItemAvatar, Avatar, Typography } from '@mui/material';
import useClasses from '../../hooks/useClasses';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import styles from './Styles';
import { useSelector } from 'react-redux';
import { imageURL } from '../../api/constants';

const Participants = ({ onCollapse }) => {
    const classes = useClasses(styles);
    const register = useSelector((state) => state.register);
    return (
        <Box classes={{ root: classes.slip }} >
            <Typography variant='h6' component="div" className={classes.participantTitle}>PARTICIPANT LIST</Typography>           
            <Divider />
            <List sx={{ width: '100%' }}>
            {
                register.participants.map((participant) => (  
                    <>                  
                    <ListItem alignItems="flex-start" className={classes.participantItem}>
                        <ListItemAvatar>
                        <Avatar alt={participant.first_name+" "+participant.last_name} src={participant.member_id != null ? imageURL + participant.member_id : '/static/images/avatar/1.jpg' } />
                        </ListItemAvatar>
                        <ListItemText
                        primary={participant.first_name +" " + participant.last_name}
                        secondary={
                            <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                { participant.type }
                            </Typography>
                            </React.Fragment>
                        }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    </>
                ))
            }
            </List>

            <Divider />
            <Box my={2} onClick={ onCollapse }>
                <Grid container sx={{ cursor: 'pointer' }}>
                    <Grid item><ArrowBackIosIcon fontSize='small' sx={{marginRight: 1,}} /></Grid>
                    <Grid item flexGrow={1}><Typography variant='body2' component="div" className={classes.viewAction}>Back to Overview</Typography></Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Participants;