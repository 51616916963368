import { Grid, Typography, Box } from "@mui/material";
import React from "react";
import styles from './Styles';
import useClasses from "../../hooks/useClasses";
import Barcode from 'react-barcode';
import { useParams } from 'react-router-dom';
import { routes as apiRoutes } from '../../api/constants';
import { sendGET } from '../../api';
import { useDispatch } from "react-redux";

const DetailsView = () => {
    const classes = useClasses(styles);
    const { barcode } = useParams();
    const dispatch = useDispatch();
    const [registration, setRegistration] = React.useState({});

    React.useEffect(() => {
        sendGET(
            apiRoutes.getRegistrationByBarcode + `?barcode=${barcode}`,
            dispatch,
            { 'Content-Type': 'application/json' }
        ).then(data => {
            console.log(data);
            setRegistration(data);
        });
    }, []);

    return (
        registration != null && registration.registration_id != undefined && (<Grid container className={classes.detailsContainer}>
            <Grid item xs={12} className={classes.section}>                
                <Typography component="div" variant="h5" className={classes.detailsHeading}>Registration Confirmed  🙏</Typography>                
                <Typography component="p" variant="body2" className={classes.generic_text}>The following details were submitted and processed as part of the registration. <strong>Please note that any changes to the slots can only be done at the venue by the registration team.</strong></Typography>
            </Grid>
            <Grid item xs={12} className={classes.section}>
                <Typography component="div" variant="h6" className={classes.subHeading}>Details</Typography>
                <Typography component="p" variant="body2" className={classes.listItemLabel}>No. of Participants</Typography>
                <Typography component="p" variant="body2" className={classes.listItem}>{ parseInt(registration.no_of_participants) + 1 }</Typography>
                <Typography component="p" variant="body2" className={classes.listItemLabel}>Contact Number</Typography>
                <Typography component="p" variant="body2" className={classes.listItem}>{ registration.contact }</Typography>
                <Typography component="p" variant="body2" className={classes.listItemLabel}>Email</Typography>
                <Typography component="p" variant="body2" className={classes.listItem}>{ registration.email }</Typography>
                <Typography component="p" variant="body2" className={classes.listItemLabel}>POC ID</Typography>
                <Typography component="p" variant="body2" className={classes.listItem}>{ registration.participants[0].member_id }</Typography>           
                <Box sx={{ width: '100%' }} className={ classes.barcode }>
                    <Barcode value={barcode} fontSize={14} height={50}/>
                </Box>                         
            </Grid>
            {
                registration.participants != null && registration.participants.map((participant, idx) => (
                    <Grid item xs={12} md={4} className={classes.section}>
                        <Typography component="div" variant="h6" className={classes.subHeading}>Participant { idx + 1 }</Typography>
                        <Grid item xs={12} md={6}>
                            <Typography component="p" variant="body2" className={classes.listItemLabel}>Name</Typography>
                            <Typography component="p" variant="body2" className={classes.listItem}>{ participant.first_name } { participant.last_name }</Typography>
                            <Typography component="p" variant="body2" className={classes.listItemLabel}>Participant Type</Typography>
                            <Typography component="p" variant="body2" className={classes.listItem}>{ participant.type }</Typography>
                            {
                                participant.type !== "VISITOR" && (
                                    <>
                                        <Typography component="p" variant="body2" className={classes.listItemLabel}>Member ID</Typography>       
                                        <Typography component="p" variant="body2" className={classes.listItem}>{ participant.member_id }</Typography>
                                    </>                                    
                                )
                            }                                                        
                            <Typography component="p" variant="body2" className={classes.listItemLabel}>Gothram</Typography>
                            <Typography component="p" variant="body2" className={classes.listItem}>{ participant.gothram }</Typography>
                            <Typography component="p" variant="body2" className={classes.listItemLabel}>Gender</Typography>
                            <Typography component="p" variant="body2" className={classes.listItem}>{ participant.gender }</Typography>
                            <Typography component="p" variant="body2" className={classes.listItemLabel}>Age</Typography>
                            <Typography component="p" variant="body2" className={classes.listItem}>{ participant.age }</Typography>
                            <Typography component="p" variant="body2" className={classes.listItemLabel}>Slots Reserved</Typography>
                            {
                                participant.slots.map((slot, _) => (
                                    <Typography component="p" variant="body2" className={classes.listItem} style={{marginBottom: 0}}>{slot.tag_label}</Typography>
                                ))
                            }
                            { participant.slots.length == 0 && <Typography component="p" variant="body2" className={classes.listItem} style={{marginBottom: 0}}>None</Typography> }                       
                        </Grid>
                    </Grid>
                ))
            }            
        </Grid>)
    );
};

export default DetailsView;
