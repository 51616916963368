import React from 'react';
import SimpleSnackbar from '../Common/SimpleSnackbar';
import LoadingView from '../Common/LoadingView';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { removeToast } from '../Common/slice';
import Header from '../Header';
import SummaryView from './SummaryView';
import { sendGET } from '../../api';
import { routes as apiRoutes } from '../../api/constants';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../router/constants';
import { modify } from '../Registration/slice';

const SummaryContainer = () => {
    const { toast, busy } = useSelector((state) => state.app);
    const session = useSelector((state) => state.session);
    const register = useSelector((state) => state.register);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        sendGET(
            apiRoutes.getRegistration + `?id=${session.id}&event=${session.event.event_id}`,
            dispatch,
            { 'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}` },
            true
        ).then(data => {
            console.log(data);
            if (data.status) {
                dispatch(modify({
                    registration_id: data.registration_id,
                    contact: data.contact,
                    event_id: data.event_id,
                    email: data.email,
                    poc_id: data.poc_id,
                    name: data.name,
                    no_of_participants: data.participants.length - 1,
                    arrival_date: data.arrival_date,
                    depart_date: data.depart_date,
                    barcode: data.barcode,
                    participants: data.participants,
                    registered_timestamp: data.registered_timestamp,
                }));
            } else {
                dispatch(modify({
                    register_id: null,
                    event_id: session.event.event_id,
                    contact: session.contact,
                    email: session.email,
                    poc_id: session.id,
                    name: session.firstName + " " + session.lastName,
                    no_of_participants: 0,
                    arrival_date: "2023-01-04",
                    depart_date: "2023-01-06",
                    barcode: "",
                    participants: [
                        {
                            participant_id: null,
                            member_id: session.id,
                            first_name: session.firstName,
                            last_name: session.lastName,
                            age: "",
                            gender: "",
                            type: "",
                            gothram: "",
                            slots: [],
                            food: []
                        }
                    ],
                    registered_timestamp: null,
                }));
                navigate(routes.REGISTER);
            }
        })
    }, []);

    return (
        <>
            <Header />
            <Box height="1px">
                <LoadingView currentlySending={busy} />
            </Box>
            {
                toast.message && (
                <SimpleSnackbar 
                    type={toast.type} 
                    message={toast.message} 
                    removeToast={removeToast} 
                />
                )
            }
            {
                register.registration_id !== null && <SummaryView />
            }
        </>
    );
};

export default SummaryContainer;