import { configureStore } from '@reduxjs/toolkit';
import sessionSlice from '../components/Login/slice';
import appSlice from '../components/Common/slice';
import registerSlice from "../components/Registration/slice";
import metadataSlice from "../components/Registration/metadata";
import storage from 'redux-persist/lib/storage';
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
  session: sessionSlice,
  app: appSlice,
  register: registerSlice,
  metadata: metadataSlice
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['app', 'register', 'metadata'],
  whitelist: ['session']
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

export const persistor = persistStore(store)