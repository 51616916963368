import React, { useState } from 'react';
import { Grid, Box, Avatar, Typography, Divider, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import useClasses from '../../hooks/useClasses';
import styles from './Styles';
import Barcode from 'react-barcode';
import { imageURL, routes as apiRoutes } from '../../api/constants';
import { sendPOST } from '../../api';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from '../../router/constants';
import { getIndianFormat, getIndianDateFormat } from '../Common/utils';
import { useNavigate } from 'react-router-dom';
import { setToast } from "../Common/slice";
import { destroy } from '../Login/slice';

const SummaryOverview = ({ onExpand }) => {
    const classes = useClasses(styles);
    const register = useSelector((state) => state.register);
    const session = useSelector((state) => state.session);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const sendWhatsappConfirmation = () => {
        sendPOST(
            apiRoutes.sendWhatsappConfirmation,
            { id: register.registration_id },
            dispatch,
            { 'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}` }
        ).then(data => {
            if (data.status) {
                dispatch(setToast({ message: data.message, type: 'success' }));
            }
        })
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const deleteRegistration = () => {
        sendPOST(
            apiRoutes.deleteRegistration,
            { registration_id: register.registration_id },
            dispatch,
            { 'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}`  }
        ).then(data => {
            console.log(data);
            if (data.status) {
                dispatch(destroy());
                dispatch(destroyRegistration());
                dispatch(destroyMetadata());
                dispatch(setToast({ message: 'Registration deleted successfully. Logging you out...', type: 'success' }));
                navigate(routes.LOGIN);
            }
        });
    };

    return (
        <>
        <Box className={classes.slip}>
            <Typography variant='h6' component="div" className={classes.summaryTitle}>YOUR REGISTRATION IS CONFIRMED 🙏</Typography>
            <Typography variant='body2' component="div" className={classes.summarySubtitle}>Please carry the confirmation with you to the venue.</Typography>
            <Divider />
            <Grid container my={2}>
                <Grid item xs={2}>
                    <Avatar variant='rounded' src={imageURL + register.poc_id}/>
                </Grid>
                <Grid item xs={10} pl={1}>
                    <Typography variant='body2' component="div" className={classes.labelText}>
                        Registration Submitted By
                    </Typography>
                    <Typography variant='body2' component="div" className={classes.valueText}>{ register.participants[0].first_name } { register.participants[0].last_name }</Typography>
                    <Typography variant='body2' component="div" className={classes.valueSubText}>ID# { register.participants[0].member_id }</Typography>
                </Grid>
            </Grid>
            { register.participants[0].type === "MEMBER" && <Chip label="MEMBER" size="small" variant='outlined' className={ classes.typeChip } color="warning"/> }
            { register.participants[0].type === "JAPATHA" && <Chip label="JAPATHA" size="small" variant='outlined' className={ classes.typeChip } color="success"/> }
            <Divider />
            <Grid container py={2} className={classes.summaryContent}>
                <Grid item xs={6} mb={2}>
                    <Typography variant='body2' component="div" className={classes.labelText}>Registered On</Typography>
                    <Typography variant='body2' component="div" className={classes.valueText}>{ getIndianFormat(register.registered_timestamp) }</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2' component="div" className={classes.labelText}>Total Participants</Typography>
                    <Typography variant='body2' component="div" className={classes.valueText}>{ register.participants.length }</Typography>
                </Grid>
                <Grid item xs={6} mb={2}>
                    <Typography variant='body2' component="div" className={classes.labelText}>Arrival at Venue</Typography>
                    <Typography variant='body2' component="div" className={classes.valueText}>{ getIndianDateFormat(register.arrival_date) }</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='body2' component="div" className={classes.labelText}>Departure from Venue</Typography>
                    <Typography variant='body2' component="div" className={classes.valueText}>{ getIndianDateFormat(register.depart_date) }</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body2' component="div" className={classes.labelText}>Reporting Place / Venue</Typography>
                    <Typography variant='body2' component="div" className={classes.valueText}>{ session.event.place }</Typography>
                </Grid>
            </Grid>
            <Divider />
            <Box my={2} onClick={onExpand}>
                <Grid container sx={{ cursor: 'pointer' }}>
                    <Grid item flexGrow={1}><Typography variant='body2' component="div" className={classes.viewAction}>View Participant Details</Typography></Grid>
                    <Grid item><ArrowForwardIosIcon fontSize='small'/></Grid>
                </Grid>
            </Box>
            <Divider />
            <Box sx={{ width: '100%' }} className={ classes.barcode }>
                <Barcode value={register.barcode} fontSize={14} height={50}/>
            </Box>
        </Box>
        <Box className={classes.slip} style={{ marginTop: 16 }}>
            <Grid container flexDirection={"row"}>
                <Grid item xs className={classes.actionItem} onClick={() => sendWhatsappConfirmation()}>
                    <WhatsAppIcon className={classes.actionIcon}/>
                    <Typography variant='body2' component="div" className={classes.actionText}>Share via<br/>WhatsApp</Typography>
                </Grid>
                <Divider orientation='vertical' flexItem/>
                <Grid item xs className={classes.actionItem} onClick={() => navigate(routes.REGISTER)}>
                    <DriveFileRenameOutlineIcon className={classes.actionIcon}/>
                    <Typography variant='body2' component="div" className={classes.actionText}>Modify<br/>Registration</Typography>
                </Grid>
                <Divider orientation='vertical' flexItem/>
                <Grid item xs className={classes.actionItem} onClick={handleOpen}>
                    <DeleteOutlineIcon className={classes.actionIcon}/>
                    <Typography variant='body2' component="div" className={classes.actionText}>Delete<br/>Registration</Typography>
                </Grid>
            </Grid>
        </Box>
        {
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Delete Registration
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to proceed with the delete action? By proceeding your complete registration will be deleted including your co-participants and slots. Once deleted you will be logged out automatically.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={() => deleteRegistration()} autoFocus>
                    Agree
                </Button>
                </DialogActions>
            </Dialog>
        }
        </>
    );
};

export default SummaryOverview;