const addZero = (value) => {
    return value > 9 ? `0${value}` : value;
};

export const getIndianFormat = (timestamp) => {
    var d = new Date(timestamp);
    return d.toLocaleDateString("en-IN") + " " + d.toLocaleTimeString("en-US");
}

export const getIndianDateFormat = (timestamp) => {
    var d = new Date(timestamp);
    return d.toLocaleDateString("en-IN");   
}