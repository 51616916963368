import React from 'react';
import { Box, AppBar, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import YouTubeIcon from '@mui/icons-material/YouTube';
import logo from '../../assets/logo.png';
import useClasses from '../../hooks/useClasses';
import styles from './Styles';
import DropdownMenu from './DropdownMenu';
import NavigationMenu from './NavigationMenu';

const Header = (props) => {
    const classes = useClasses(styles);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const { showTopHeader } = props

    return (
        <Box sx={{ flexGrow: 1 }}>
            {
                showTopHeader && (
                    <AppBar position="static" classes={{ root: classes.topHeader }}>
                        <Toolbar classes={{ root: classes.topToolbar }}>
                            <Typography variant="body1" component="div" className={classes.headerTag} sx={{ flexGrow: 1 }}>
                                Sandhyavandana Abhyasana Sikshana Samiti
                            </Typography>
                            <YouTubeIcon className={classes.socialIcons}/>
                            <FacebookRoundedIcon className={classes.socialIcons}/>
                        </Toolbar>
                    </AppBar>
                )
            }
            <AppBar position="static" classes={{ root: classes.appBar }}>
                <Toolbar classes={{ root: classes.toolbar }}>
                    <img src={logo} className={classes.logo} alt='logo' />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className={classes.appName}>
                        Shatakoti Gayatri Maha Mantra Japa Yagnam
                    </Typography>
                    <NavigationMenu />
                    {
                        /*isMobile ? (
                            <DropdownMenu />
                        ) : (
                            <NavigationMenu />
                        )*/
                    }
                </Toolbar>
            </AppBar>
        </Box>
    );
};

Header.defaultProps = {
    showTopHeader: false
};

export default Header;