import React from 'react';
import MuiPhoneNumber from "material-ui-phone-number";
import useClasses from '../../hooks/useClasses';
import styles from './Styles';
import logo from '../../assets/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import isEmail from 'validator/lib/isEmail';
import { sendPOST, sendGET } from '../../api';
import { Box, Grid, Typography, Button, TextField, MenuItem } from '@mui/material';
import { setToast } from '../Common/slice';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../router/constants';
import { routes as apiRoutes } from '../../api/constants';
import { create } from '../Login/slice';

const NonMemberLoginView = () => {
    const classes = useClasses(styles);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [contactNumber, changeContactNumber] = React.useState('');
    const [response, setResponse] = React.useState({ status: false, is_member: false, uuid: null });
    const [otp, changeOTP] = React.useState('');
    const [events, setEvents] = React.useState([]);
    const [event, setEvent] = React.useState(0);
    const session = useSelector((state)=>state.session);
    const [profile, setProfile] = React.useState({ id: null, first_name: null, last_name: null, contact: null, email: null });
    const [token, setToken] = React.useState(null);

    const signIn = () => {
        if (profile.id === null || profile.id === undefined || profile.id === "" ||
            profile.first_name === null || profile.first_name === undefined || profile.first_name === "" ||
            profile.last_name === null || profile.last_name === undefined || profile.last_name === "" ||
            profile.contact === null || profile.contact === undefined || profile.contact === "" ||
            profile.email === null || profile.email === undefined || profile.email === ""
        ) {
            dispatch(setToast({ message: 'Please fill all the required fields to proceed.', type: 'error' }));
        } else {
            sendPOST(
                apiRoutes.loginAsNonMember,
                { id: profile.id, first_name: profile.first_name, last_name: profile.last_name, email: profile.email, contact: profile.contact },
                dispatch,
                {
                    'Authorization': `Bearer ${token}`
                }
            ).then(data => {
                const current_event = events.filter(e => e.event_id == event);
                dispatch(create({ token: data.token, event: current_event[0], is_member: false }));
            });
        }        
    }
    
    const sendOTP = () => {
        sendPOST(
            contactNumber.includes('+91') ? apiRoutes.sendSMSOTPNonMember : apiRoutes.generateOTPNonMember,
            { contact: contactNumber }, 
            dispatch
        ).then(data => {
            console.log(data);
            setResponse(data);            
        });
    }

    const verifyOTP = () => {
        sendPOST(
            apiRoutes.verifyOTPNonMember,
            { contact: contactNumber, event_id: event, otp: otp },
            dispatch
        ).then(data => {
            if (data.registered) {
                const current_event = events.filter(e => e.event_id == event);
                dispatch(create({ token: data.token, event: current_event[0], is_member: false }));
            } else {
                setToken(data.token);
                setProfile((profile) => {
                    return {
                        ...profile,
                        id: data.uuid,
                        contact: contactNumber
                    }
                })
            }
        });
    };

    React.useEffect(() => {
        if (session.id !== null && session.id !== 0)
            navigate(routes.SUMMARY);
        else if (session.id !== null && session.id === 0)
            navigate(routes.SPOT);
    }, [session.id]);

    const getActiveEvents = () => {
        sendGET(
            apiRoutes.getActiveEvents,
            dispatch
        ).then(data => {
            if (data.status) {
                setEvents(data.events);
                setEvent(data.events[0].event_id)
            }
        })
    };

    React.useEffect(getActiveEvents, []);
    
    return (
        <Grid container className={classes.loginContainer} flexDirection="row">
            <Box className={classes.loginForm}>
                <img src={logo} className={classes.logo} />
                <Typography variant='h6' component="div" className={classes.welcomeText}>K C Das Memorial Charitable Trust - Non Member Login</Typography>
                <Typography variant='body2' component='p' className={classes.helperText}>
                    {
                        response.status === false ? 
                        'Please enter your WhatsApp contact number below to proceed.' : 
                        profile.contact === null ? 'We sent an OTP to your registered mobile number. Please enter the OTP below and click Proceed.' : 'Please enter your details below to proceed'
                    }                    
                </Typography>   
                <TextField
                    select
                    label="Event"
                    autoFocus={true}
                    name="event"
                    value={event}
                    className={classes.textField}
                    InputProps={{
                        required: true,
                        classes: { input: classes.textFieldInput },
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={response.status}
                    onChange={(e) => setEvent(e.target.value)}
                >
                    {
                        events.map((event) => (
                            <MenuItem key={event.event_id} value={event.event_id}>{ event.event_name } - { event.place }</MenuItem>
                        ))
                    }
                </TextField>             
                <Grid container>
                    <Grid item xs={12}>
                        <MuiPhoneNumber
                            label="WhatsApp Number"
                            fullWidth
                            value={contactNumber}
                            onChange={ (value) => { changeContactNumber(value) } }
                            defaultCountry={"in"}
                            variant='outlined'
                            disabled={profile.contact !== null}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                required: true,
                                classes: {
                                    input: classes.textFieldInput
                                }
                            }}
                            className={classes.textField}
                        />
                    </Grid>
                    {
                        response?.status && profile.contact === null && (
                            <Grid item xs={12} >
                                <TextField
                                    label="OTP"
                                    type="number"
                                    value={otp}
                                    className={classes.textField}
                                    onChange={ (event) => { changeOTP(event.target.value) } }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        required: true,
                                        type: 'password',
                                        classes: {
                                            input: classes.textFieldInput
                                        }
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                        )
                    }
                    {
                        profile.contact !== null && (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Surname"
                                        value={profile?.first_name}
                                        className={classes.textField}
                                        variant='outlined'
                                        required={true}
                                        InputProps={{
                                            classes: { input: classes.textFieldInput },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => setProfile((profile) => { return {...profile, first_name: e.target.value} })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Given Name"
                                        value={profile?.last_name}
                                        className={classes.textField}
                                        variant='outlined'
                                        required={true}
                                        InputProps={{
                                            classes: { input: classes.textFieldInput },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => setProfile((profile) => { return {...profile, last_name: e.target.value} })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Email"
                                        error={profile.email !== "" && profile.email !== undefined && profile.email !== null && !isEmail(profile.email)}
                                        required
                                        type="email"
                                        value={profile.email}
                                        helperText={profile.email !== "" && profile.email !== undefined && profile.email !== null && !isEmail(profile.email) ? "Invalid email" : ''}
                                        className={classes.textField}
                                        variant='outlined'
                                        InputProps={{
                                            classes: { input: classes.textFieldInput },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => setProfile((profile) => { return {...profile, email: e.target.value} })}
                                    />
                                </Grid>
                            </>
                        )
                    }
                </Grid>  
                {
                    !response.status && (
                        <Box className={classes.forgotBox}>
                            <Typography variant='body2' component="div" className={classes.forgotPass} onClick={() => { navigate(routes.FORGOT_PASS) }}>Are you a member of KCDT? Click here to login with mobile number</Typography>
                        </Box>
                    )
                }                                      
                <Button 
                    variant='container' 
                    className={classes.signIn} 
                    onClick={ response.status === false ? sendOTP : profile.contact === null ? verifyOTP : signIn  }
                >
                    { response.status === false ? 'Send OTP' : profile.contact === null ? 'Verify OTP' : 'Sign In' }
                </Button>
            </Box>
        </Grid>
    );
};

export default NonMemberLoginView;
