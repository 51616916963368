import React from "react";
import styles from './Styles';
import useClasses from '../../hooks/useClasses';
import { useSelector } from 'react-redux';
import { Box, Card, Divider, Typography, Grid } from '@mui/material';

const ParayanaSlot = (props) => {
    const classes = useClasses(styles);
    const { addSlot, removeSlot, parayanas, date, participant } = props;
    let date_split = date.split("-");

    const printMonth = (value) => {
        return {
            1 : 'Jan',
            2 : 'Feb',
            3 : 'Mar',
            4 : 'Apr',
            5 : 'May',
            6 : 'June',
            7 : 'July',
            8 : 'Aug',
            9 : 'Sept',
            10: 'Oct',
            11: 'Nov',
            12: 'Dec'
        } [value]
    }

    const handleSelection = (parayana) => {
        if (participant.slots.includes(parayana.tag_id)) {
            removeSlot(parayana.tag_id)
        } else {
            addSlot(parayana.tag_id)
        }
    }

    return (
        <Card classes={{root:classes.foodContainer}}>
            <Box className={classes.foodSectionHeader}><Typography variant='h6' className={classes.foodSectionTitle}>Ramayana Parayana on {`${date_split[2]} ${printMonth(parseInt(date_split[1]))} ${date_split[0]}`}</Typography></Box>
            <Grid container>
                {
                    parayanas.map((parayana, idx) => (
                        <>
                            <Box item xs={3} classes={{root:classes.parayanaSectionItem}} className={ participant.slots.includes(parayana.tag_id) ? classes.parayanaSelectedItem : classes.parayanaSectionItem} onClick={() => handleSelection(parayana)}>
                                <Typography variant='body2' component="div" className={participant.slots.includes(parayana.tag_id) ? classes.foodSelectedLabel : classes.foodItemLabel}>{ parayana.tag_label }</Typography>
                                <Typography variant='body2' component="div" classes={{ root: classes.foodItemInfo }} className={participant.slots.includes(parayana.tag_id) ? classes.foodSelectedInfo : classes.foodItemInfo}>{ participant.slots.includes(parayana.tag_id) ? 'Selected' : 'Click to choose' }</Typography>
                            </Box>
                            {
                                (idx + 1) % 4 != 0 && <Divider orientation="vertical" flexItem/>
                            }
                        </>
                    ))
                }                                                
            </Grid>
        </Card>
    );
}

export default ParayanaSlot;