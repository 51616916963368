import React, { useEffect, useState } from 'react';
import useClasses from '../../hooks/useClasses';
import { 
    Box, TextField, InputAdornment, Grid, 
    MenuItem, Avatar, Autocomplete, IconButton, 
    Divider, Typography, Alert, RadioGroup, 
    FormControlLabel, Radio
} from '@mui/material';
import styles from './Styles';
import { useSelector, useDispatch } from 'react-redux';
import { imageURL } from '../../api/constants';
import { updateParticipant, updateSlot } from './slice';
import SearchIcon from '@mui/icons-material/Search';
import { sendGET } from '../../api';
import { routes as apiRoutes } from '../../api/constants';
import Slot from './Slot';
import FoodPicker from './FoodPicker';
import ParayanaSlot from './ParayanaSlot';

function ParticipantsStep({ index }) {
    const classes = useClasses(styles);
    const dispatch = useDispatch();
    const session = useSelector((state) => state.session);
    const register = useSelector((state) => state.register);
    const metadata = useSelector((state) => state.metadata);
    const [participant_id, setParticipantID] = useState(register.participants[index].participant_id);
    const [member_id, setMemberID] = useState(register.participants[index].member_id);
    const [type, setType] = useState(register.participants[index].type);
    const [first_name, setFirstName] = useState(register.participants[index].first_name);
    const [last_name, setLastName] = useState(register.participants[index].last_name);
    const [gothram, setGothram] = useState(register.participants[index].gothram);
    const [gothramOthers, setGothramOthers] = useState("");
    const [gender, setGender] = useState(register.participants[index].gender);
    const [age, setAge] = useState(register.participants[index].age);
    const [isSrisevak, setIsSrisevak] = useState(register.participants[index].is_srisevak);
    const [slots, setSlots] = useState(register.participants[index].slots);
    const [food, setFood] = useState(register.participants[index].food);
    const [isPrimaryPartipant, setIsPrimaryParticipant] = useState(false);
    const [show_parayana, setShowParayana] = React.useState(false);

    useEffect(() => {
        const participant = {
            participant_id: participant_id,
            member_id: member_id,
            type: type.toUpperCase(),
            first_name: first_name.toUpperCase(),
            last_name: last_name.toUpperCase(),
            gothram: gothram.toUpperCase() == 'OTHERS' ? gothramOthers.toUpperCase() : gothram.toUpperCase(),
            gender: gender,
            age: age,
            slots: slots,
            food: food,
            is_srisevak: isSrisevak
        };
        dispatch(updateParticipant({ index: index, participant: participant }));
    }, [member_id, type, first_name, last_name, gothram, gothramOthers, gender, age, slots, food, isSrisevak]);

    const getMemberDetails = (id) => {
        if (id == "" || id == null) {
            return;
        }
        sendGET(
            apiRoutes.getMember + `?id=${id}`,
            dispatch,
            { 'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}` }
        ).then(data => {
            if (data.status) {
                setMemberID(data.MemberID);
                setType(data.JapathaEnrollment === "1" ? "JAPATHA" : "MEMBER");
                setFirstName(data.FirstName);
                setLastName(data.LastName);
                setGothram(data.Gothram);
                setGender(data.Gender);
                setAge(data.Age);
            }
        });
    };

    useEffect(() => {
        setParticipantID(register.participants[index].participant_id);
        setMemberID(register.participants[index].member_id);
        setFirstName(register.participants[index].first_name);
        setLastName(register.participants[index].last_name);
        setGothram(register.participants[index].gothram);
        setGender(register.participants[index].gender);
        setAge(register.participants[index].age);
        setType(register.participants[index].type);
        setSlots(register.participants[index].slots);
        setFood(register.participants[index].food);
        setIsSrisevak(register.participants[index].is_srisevak);
        setIsPrimaryParticipant(index == 0 ? true : false);
        if (index == 0 && register.participants[index].member_id !== null && (register.participants[index].type === null || register.participants[index].type === "")) {
            getMemberDetails(register.participants[index].member_id);            
        }        
    }, [index]);



    const addSlot = (tag_id) => {
        let slots_temp = [
            ...slots
        ];
        if (slots_temp.indexOf(tag_id) <= -1) {
            slots_temp.push(tag_id);
            setSlots(slots_temp);
        }
    };

    const removeSlot = (tag_id) => {
        let slots_temp = [
            ...slots
        ];
        let pos = slots_temp.indexOf(tag_id);
        if (pos > -1) {
            slots_temp.splice(pos, 1);
        }
        setSlots(slots_temp);
    };

    const addFood = (tag_id) => {
        let food_temp = [
            ...food
        ];
        if (food_temp.indexOf(tag_id) <= -1) {
            food_temp.push(tag_id);
            setFood(food_temp);
        }
    };

    const removeFood = (tag_id) => {
        let food_temp = [
            ...food
        ];
        let pos = food_temp.indexOf(tag_id);
        if (pos > -1) {
            food_temp.splice(pos, 1);
        }
        setFood(food_temp);
    };

    const eligibleForSrisevak = () => {
        return (register.arrival_date === "2024-07-25" && register.depart_date === "2024-08-01")
    }

    React.useEffect(() => {
        const slots = [...register.participants[index].slots];
        if (!show_parayana) {
            Object.keys(metadata.parayana).map((date) => {
                metadata.parayana[date].map((parayana) => {
                    if (slots.includes(parayana.tag_id)) {
                        slots.splice(slots.indexOf(parayana.tag_id), 1)
                    }
                })
            })
        }
        setSlots(slots);
    }, [show_parayana])

    return (
        <Box pt={2} mb={2} className={classes.formContainer}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextField
                        select
                        label="Type"
                        required
                        autoFocus={true}
                        value={type}
                        className={classes.inputField}
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setType(e.target.value)}
                    >
                        {
                            session.is_member && <MenuItem key={"JAPATHA"} value={"JAPATHA"}>Japatha</MenuItem>
                        }                        
                        {
                            session.is_member && <MenuItem key={"MEMBER"} value={"MEMBER"}>Member</MenuItem>
                        }
                        {
                            (!isPrimaryPartipant || (isPrimaryPartipant && !session.is_member)) && <MenuItem key={"VISITOR"} value={"VISITOR"}>Visitor</MenuItem>
                        }                        
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Member ID"
                        type="number"
                        value={index === 0 || type != "VISITOR" ? member_id : ""}
                        disabled={!session.is_member || index === 0 || type == "VISITOR" ? true : false}
                        helperText={index !== 0 && type !== "VISITOR" && session.is_member ? "Click the search button after entering Member ID to auto fetch details" : ''}
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Avatar
                                        alt="Member Image"
                                        src={index==0 || type != "VISITOR" ? (imageURL + (register.participants[index].member_id !== null ? register.participants[index].member_id : "2427")): ""}
                                        sx={{ width: 24, height: 24 }}
                                    />
                                </InputAdornment>
                            ),
                            endAdornment: index !== 0 && type !== "VISITOR" && (
                                <IconButton onClick={() => getMemberDetails(member_id)}>
                                    <SearchIcon />
                                </IconButton>
                            )
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        className={classes.inputField}
                        onBlur={(e) => getMemberDetails(e.target.value)}
                        onChange={(e) => setMemberID(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        label="First Name"
                        value={first_name}
                        className={classes.inputField}
                        variant='outlined'
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        label="Given Name"
                        value={last_name}
                        className={classes.inputField}
                        variant='outlined'
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </Grid>                

                <Grid item xs={6}>
                    <TextField
                        select
                        label="Gender"
                        value={gender}
                        className={classes.inputField}
                        variant='outlined'
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setGender(e.target.value)}
                        disabled={type == "VISITOR" ? false : ( gender != "" ? true : false )}
                    >
                        <MenuItem key={"M"} value={"M"}>Male</MenuItem>
                        <MenuItem key={"F"} value={"F"}>Female</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Age"
                        type="number"
                        value={age}
                        className={classes.inputField}
                        variant='outlined'
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setAge(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        disablePortal
                        options={metadata.gothrams}
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                        }}
                        className={classes.inputField}
                        value={gothram}                        
                        renderInput={(params) => <TextField {...params} label="Gothram"
                            helperText="Select OTHERS if your gothram is not in the dropdown"
                            InputLabelProps={{
                                shrink: true,
                            }} />}
                        onChange={(e) => setGothram(e.target.innerHTML)}
                    />
                </Grid>
                {
                    gothram == 'OTHERS' &&
                    <Grid item xs={6}>
                        <TextField
                            label="Please specify if others"
                            type="text"
                            value={gothramOthers}
                            className={classes.inputField}
                            variant='outlined'
                            InputProps={{
                                classes: { input: classes.textFieldInput },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setGothramOthers(e.target.value)}
                        />
                    </Grid>
                }                
            </Grid>
            {
                eligibleForSrisevak() && (
                    <>
                        <Divider sx={{ marginTop: 2 }} />
                        <Typography variant='body1' component="div" className={classes.slotPickTitle}>Will <strong>{`${first_name} ${last_name}`}</strong> render services as a Srisevak during the event?</Typography>
                        <Alert severity="info" style={{ marginTop: 16, marginBottom: 16 }}>
                            All Srisevaks are expected to report at Ayodhya two days before the event starts and leave one day after the event ends i.e arrive by 25th July 2024 and leave on or after 1st August 2024. This is to ensure proper planning and coordination among the Srisevaks.
                        </Alert>
                        <Grid container spacing={1}>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={isSrisevak}
                                name="radio-buttons-group"
                                style={{ display: 'flex', flexDirection: 'row', marginLeft: 16 }}
                                onChange={(e, value) => setIsSrisevak(value)}
                            >
                                <FormControlLabel value="YES" control={<Radio />} label="Yes" />
                                <FormControlLabel value="NO" control={<Radio />} label="No" />
                            </RadioGroup>
                        </Grid>
                    </>
                )
            }
            {
                type === "JAPATHA" && (
                    <>
                        <Divider sx={{ marginTop: 2 }} />
                        <Typography variant='body1' component="div" className={classes.slotPickTitle}>Choose upto 10 slots for {`${first_name} ${last_name}`} by clicking below.</Typography>
                        <Typography variant='body1' component="div" className={classes.slotPickTitle} style={{ color: 'red' }}>Note: Only those who have done japam atleast 50,000 will be allowed to perform homam. In case your japa sankhya is less than 50,000 by 20th of July 2024, your slot will be automatically cancelled.</Typography>
                        <Grid container spacing={1}>
                            {
                                metadata.slots.map((slot) => (
                                    slot.max_tags - slot.count > 0 && slot.type === "HOMAM" && (<Grid item xs={6}>
                                        <Slot
                                            index={index}
                                            tag_label={slot.tag_label}
                                            tag_id={slot.tag_id}
                                            removeSlot={(tag_id) => removeSlot(tag_id)}
                                            addSlot={(tag_id) => addSlot(tag_id)}
                                            remaining={slot.max_tags - slot.count}
                                            participant={register.participants[index]}
                                            selectedText={` You selected this slot. Please report at Yaagasala 1hr prior to your slot time, else your slot will be cancelled and allocated to someone else.`}
                                        />
                                    </Grid>)
                                ))
                            }
                        </Grid>
                    </>
                )
            }
            <Divider sx={{ marginTop: 2 }} />
            <Typography variant='body1' component="div" className={classes.slotPickTitle}>Will <strong>{`${first_name} ${last_name}`}</strong> participate in Ramayana Parayana being conducted at Ayodhya?</Typography>
            <Grid container spacing={1}>
                <RadioGroup
                    value={show_parayana ? "YES": "NO"}
                    style={{ display: 'flex', flexDirection: 'row', marginLeft: 16 }}
                    onChange={(e, value) => setShowParayana(value === "YES" ? true : false)}
                >
                    <FormControlLabel value="YES" control={<Radio />} label="Yes" />
                    <FormControlLabel value="NO" control={<Radio />} label="No" />
                </RadioGroup>
            </Grid>
            {
                show_parayana && (<>
                    <Typography variant='body1' component="div" className={classes.slotPickTitle} style={{ marginBottom: 32 }}>Please select slots for Ramayana Parayanam below.</Typography>
                    <Grid container spacing={1}>
                        {
                            Object.keys(metadata.parayana).map((date) => (
                                <ParayanaSlot
                                    date={date}
                                    addSlot={addSlot}
                                    removeSlot={removeSlot}
                                    parayanas={metadata.parayana[date]}
                                    participant={register.participants[index]}
                                />
                            ))
                        }
                    </Grid>
                </>)
            }
            {
                gender === "F" && (
                    <>
                        <Divider sx={{ marginTop: 2 }} />
                        <Typography variant='body1' component="div" className={classes.slotPickTitle}>Choose archana slots for {`${first_name} ${last_name}`} by clicking below.</Typography>
                        <Grid container spacing={1}>
                            {
                                metadata.slots.map((slot) => (
                                    slot.max_tags - slot.count > 0 && slot.type === "ARCHANA" && (<Grid item xs={6}>
                                        <Slot
                                            index={index}
                                            tag_label={slot.tag_label}
                                            tag_id={slot.tag_id}
                                            removeSlot={(tag_id) => removeSlot(tag_id)}
                                            addSlot={(tag_id) => addSlot(tag_id)}
                                            remaining={slot.max_tags - slot.count}
                                            participant={register.participants[index]}
                                        />
                                    </Grid>)
                                ))
                            }
                        </Grid>
                    </>
                )
            }
            <Divider sx={{ marginTop: 2 }} />
            <Typography variant='body1' component="div" className={classes.slotPickTitle}>Will <strong>{`${first_name} ${last_name}`}</strong> be joining for breakfast / lunch / dinner? Choose below:</Typography>
            {
                Object.keys(metadata.food).map((date, idx) => (
                    <FoodPicker
                        index={index}
                        date={date}
                        foods={metadata.food[date]}
                        removeFood={(tag_id) => removeFood(tag_id)}
                        addFood={(tag_id) => addFood(tag_id)}
                    />
                ))
            }
        </Box>
    );
}

export default ParticipantsStep;