import { theme } from '../../theme';
const styles = (theme) => ({
    summaryView: {
        padding: 32,
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            padding: 64,
            paddingTop: 32
        }
    },
    slip: {
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: 24,
        boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 4px"
    },
    labelText: {
        color: '#646464', 
        fontSize: 12,
        fontWeight: 300,
        marginBottom: 4
    },
    valueText: {
        color: '#323232',
        fontSize: 12,
        fontWeight: 500
    },
    valueSubText: {
        color: '#646464',
        fontSize: 12,
        fontWeight: 500
    },
    barcode: {
        paddingTop: 8,
        display: 'flex',
        justifyContent: 'center'
    },
    summaryTitle: {
        fontSize: 14,
        marginBottom: 0,
        color: '#323232'
    },
    summarySubtitle: {
        fontSize: 12,
        marginBottom: 16,
        color: '#646464'
    },
    actionItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        cursor: 'pointer'
    },
    actionIcon: {
        color: '#646464',
        marginBottom: 8,
    },
    actionText: {
        textAlign: 'center',
        fontSize: 12
    },
    instructionsTitle: {
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 8
    },
    instructions: {
        fontSize: 14,
        color: '#646464',
    },
    instructionList: {
        fontFamily: "'Open Sans', sans-serif",
        fontSize: 14,
        color: '#646464',
        paddingLeft: 16,
        '& li': {
            marginBottom: 16
        },
    },
    typeChip: {
        fontSize: 12,
        fontWeight: 400,
        marginBottom: 10,
        marginRight: 8
    },
    viewAction: {
        color: '#323232'
    },
    participantItem: {
        paddingLeft: 0,
        paddingRight: 0,
        '& .MuiTypography-root': {
            fontSize: 14
        }
    },
    participantTitle: {
        fontSize: 16,
        marginBottom: 8
    }
});

export default styles;