import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Provider as ReduxProvider } from 'react-redux';
import AppRouter from './router/AppRouter';
import { theme } from './theme';
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';

function App() {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AppRouter />
          </ThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;