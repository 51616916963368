import React from 'react';
import MuiPhoneNumber from "material-ui-phone-number";
import useClasses from '../../hooks/useClasses';
import styles from './Styles';
import logo from '../../assets/logo.png';
import { useDispatch } from 'react-redux';
import { sendPOST, sendGET } from '../../api';
import { Box, Grid, Typography, Button, TextField, MenuItem } from '@mui/material';
import { setToast } from '../Common/slice';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../router/constants';
import { routes as apiRoutes } from '../../api/constants';
import { create } from '../Login/slice';

const ForgotPasswordView = () => {
    const classes = useClasses(styles);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [contactNumber, changeContactNumber] = React.useState('');
    const [response, changeResponse] = React.useState({ otp: '', id: null });
    const [otp, changeOTP] = React.useState('');
    const [events, setEvents] = React.useState([]);
    const [event, setEvent] = React.useState(0);

    const sendOTP = () => {
        sendPOST(
            contactNumber.includes('+91') ? apiRoutes.sendSMSOTP: apiRoutes.generateOTP,
            { contact: contactNumber }, 
            dispatch
        ).then(data => {
            changeResponse(data);
        });
    }

    const getActiveEvents = () => {
        sendGET(
            apiRoutes.getActiveEvents,
            dispatch
        ).then(data => {
            if (data.status) {
                setEvents(data.events);
                setEvent(data.events[0].event_id)
            }
        })
    };

    React.useEffect(getActiveEvents, []);

    const signIn = () => {
        console.log(otp, response.otp);
        if (otp === response.otp) {
            const current_event = events.filter(e => e.event_id == event);
            dispatch(create({ token: response.token, event: current_event[0], is_member: true }));
            navigate(routes.SUMMARY);
        } else {
            dispatch(setToast({ message: 'Incorrect OTP entered. Please try again by entering the OTP you received on your registered mobile.', type: 'error' }));
        }
    };
    
    return (
        <Grid container className={classes.loginContainer} flexDirection="row">
            <Box className={classes.loginForm}>
                <img src={logo} className={classes.logo} />
                <Typography variant='h6' component="div" className={classes.welcomeText}>K C Das Memorial Charitable Trust - Forgot Password</Typography>
                <Typography variant='body2' component='p' className={classes.helperText}>
                    {
                        response.otp === '' ? 
                        'Please enter your registered contact number below to proceed.' : 
                        'We sent an OTP to your registered mobile number. Please enter the OTP below and click Proceed.'
                    }                    
                </Typography>
                <TextField
                    select
                    label="Event"
                    autoFocus={true}
                    name="event"
                    value={event}
                    className={classes.textField}
                    InputProps={{
                        required: true,
                        classes: { input: classes.textFieldInput },
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={response.status}
                    onChange={(e) => setEvent(e.target.value)}
                >
                    {
                        events.map((event) => (
                            <MenuItem key={event.event_id} value={event.event_id}>{ event.event_name } - { event.place }</MenuItem>
                        ))
                    }
                </TextField>
                <Grid container>
                    <Grid item xs={12}>
                        <MuiPhoneNumber
                            label="Contact Number"
                            fullWidth
                            value={contactNumber}
                            onChange={ (value) => { changeContactNumber(value) } }
                            defaultCountry={"in"}
                            variant='outlined'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                required: true,
                                classes: {
                                    input: classes.textFieldInput
                                }
                            }}
                            className={classes.textField}
                        />
                    </Grid>
                    {
                        response.otp !== '' && (
                            <Grid item xs={12} >
                                <TextField
                                    label="OTP"
                                    type="number"
                                    value={otp}
                                    className={classes.textField}
                                    onChange={ (event) => { changeOTP(event.target.value) } }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        required: true,
                                        type: 'password',
                                        classes: {
                                            input: classes.textFieldInput
                                        }
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                        )
                    }
                </Grid>                        
                <Box className={classes.forgotBox}>
                    <Typography variant='body2' component="div" className={classes.forgotPass} onClick={() => { navigate(routes.LOGIN) }}>Back to Login</Typography>
                </Box>
                <Button 
                    variant='container' 
                    className={classes.signIn} 
                    onClick={ response.otp === '' ? sendOTP : signIn  }
                >
                    { response.otp === '' ? 'Send OTP' : 'Proceed' }
                </Button>
            </Box>
        </Grid>
    );
};

export default ForgotPasswordView;