import * as React from 'react';
import { Box, Card, Typography } from '@mui/material';
import useClasses from '../../hooks/useClasses';
import styles from './Styles';

const Slot = (props) => {
    const classes = useClasses(styles);
    const { index, addSlot, removeSlot, tag_label, tag_id, remaining, participant, selectedText } = props;
    const [selected, setSelected] = React.useState(false);

    React.useEffect(() => {
        if (selected) {
            addSlot(tag_id);
        } else {
            removeSlot(tag_id);
        }
    }, [selected]);

    React.useEffect(() => {
        setSelected(participant.slots.includes(tag_id));
    }, [index]);

    const handleSelection = () => {
        if (!selected && participant.slots.length < 10) {
            setSelected(true);
        } else {
            setSelected(false);
        }
    };

    return (
        <Card classes={{root:classes.slotContainer}} className={selected ? classes.selectedSlotContainer : ''} onClick={() => handleSelection()}>
            <Box className={classes.slotInner}>
                <Typography variant='body2' component="p" className={!selected ? classes.slotTitle : classes.slotTitleSelected}>{selected ?<span style={{color: "white"}}>&#10003;&nbsp;{ tag_label }</span>:`${ tag_label }`}</Typography>
                <Typography variant='body2' component="p" className={!selected ? classes.slotInfo : classes.slotInfoSelected}>{ selected ? selectedText : `Remaining slots: ${remaining}` }</Typography>                
            </Box>
        </Card>
    );
}

Slot.defaultProps = {
    selectedText: `Selected`
};

export default Slot;