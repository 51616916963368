import React, { useEffect } from 'react';
import useClasses from '../../hooks/useClasses';
import styles from './Styles';
import logo from '../../assets/logo.png';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendGET, sendPOST } from '../../api';
import { Box, Grid, Typography, TextField, Button, Select, MenuItem, ButtonGroup } from '@mui/material';
import { create } from './slice';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../router/constants'
import { routes as apiRoutes } from '../../api/constants';

const LoginView = () => {
    const classes = useClasses(styles);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [pass, changePass] = React.useState('');
    const [memberId, changeMemberID] = React.useState(id ? id : '');
    const [event, setEvent] = React.useState(0);
    const session = useSelector((state)=>state.session);
    const [events, setEvents] = React.useState([]);
    const [isMember, setIsMember] = React.useState(false);

    const signIn = () => {
        sendPOST(
            apiRoutes.login,
            { id: memberId, password: pass }, 
            dispatch
        ).then(data => {
            if (data.status) {
                const current_event = events.filter(e => e.event_id == event);
                dispatch(create({ token: data.token, event: current_event[0], is_member: true }));
            }
        })
    }

    const getActiveEvents = () => {
        sendGET(
            apiRoutes.getActiveEvents,
            dispatch
        ).then(data => {
            if (data.status) {
                setEvents(data.events);
                setEvent(data.events[0].event_id)
            }
        })
    };

    useEffect(() => {
        if (session.id !== null && session.id !== 0)
            navigate(routes.SUMMARY);
        else if (session.id !== null && session.id === 0)
            navigate(routes.SPOT);
    }, [session.id]);

    useEffect(getActiveEvents, []);
    
    return (
        <Grid container className={classes.loginContainer} flexDirection="row">
            <Box className={classes.loginForm}>
                <img src={logo} className={classes.logo} />
                <Typography variant='h6' component="div" className={classes.welcomeText}>K C Das Memorial Charitable Trust - Member Login</Typography>
                <Typography variant='body2' component='p' className={classes.helperText}>Kindly enter below details to proceed!</Typography>
                {
                    !isMember && (
                        <>
                            <Typography variant='body2' component='p' className={classes.labelText}>Do you have a Member ID?</Typography>
                            <ButtonGroup variant='outlined' className={classes.buttonGroup}>
                                <Button onClick={() => setIsMember(true)}>Yes</Button>
                                <Button onClick={() => navigate(routes.NON_MEMBER_LOGIN)}>No</Button>
                            </ButtonGroup>
                        </>                        
                    )
                }                
                {isMember && (
                    <>
                        <TextField
                            select
                            label="Event"
                            autoFocus={true}
                            name="event"
                            value={event}
                            className={classes.textField}
                            InputProps={{
                                required: true,
                                classes: { input: classes.textFieldInput },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setEvent(e.target.value)}
                        >
                            {
                                events.map((event) => (
                                    <MenuItem key={event.event_id} value={event.event_id}>{ event.event_name } - { event.place }</MenuItem>
                                ))
                            }
                        </TextField>
                        <TextField
                            id="filled-number"
                            label="Member ID"
                            type="number"
                            value={memberId}
                            onChange={ (event) => changeMemberID(event.target.value) }
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                required: true,
                                classes: {
                                    input: classes.textFieldInput
                                }
                            }}
                            variant="outlined"
                        />
                        <TextField
                            id="filled-number"
                            label="Password"
                            type="number"
                            className={classes.textField}
                            value={pass}
                            onChange={ (event) => changePass(event.target.value) }
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                required: true,
                                type: 'password',
                                classes: {
                                    input: classes.textFieldInput
                                }
                            }}
                            variant="outlined"
                        />
                        <Box className={classes.forgotBox} onClick={() => { navigate(routes.FORGOT_PASS) }}>
                            <Typography variant='body2' component="div" className={classes.forgotPass}>Forgot Member ID or Password?</Typography>
                        </Box>
                        <Button 
                            variant='container' 
                            className={classes.signIn} 
                            onClick={ signIn }
                        >
                            Sign In
                        </Button>
                    </>
                )}
            </Box>
        </Grid>
    );
};

export default LoginView;
