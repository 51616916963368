import { theme } from '../../theme';
const styles = (theme) => ({
    boundingBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    registerTitle: {
        fontSize: 18,
        fontWeight: 600,
        textAlign: 'center',
        marginBottom: 36,
    },
    stepper: {
        marginBottom: 48,
        marginTop: 36,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        }
    },
    donateBtn: {
        backgroundColor: '#FFC107',
        color: '#000000',
        fontSize: 12,
        textTransform: 'capitalize',
        marginLeft: 16,
        '&:hover': {
            backgroundColor: '#FFC107',
            cursor: 'pointer'
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: 16
        }
    },
    stepContent: {
        height: `calc(100vh - 330px)`,
        padding: 24,
        paddingTop: 0,
        paddingLeft: 36,
        overflow: 'auto',
        [theme.breakpoints.down('md')]: {
            height: `calc(100vh - 195px)`,
            paddingLeft: 16,
            paddingRight: 16,
        }
    },
    stepDesc: {
        fontSize: 14,
        color: '#646464',
        textAlign: 'left',
        lineHeight: 2,
        [theme.breakpoints.down('md')]: {
            paddingTop: 8,
            lineHeight: 1.6
        }
    },
    notes: {
        fontSize: 14,
        color: '#646464',
        textAlign: 'left',
        lineHeight: 1.6,
        padding: 8,
        paddingLeft: 10
    },
    mobileLabel: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: 16, 
        bgcolor: '#FFFFFF',
        borderBottom: '1px solid #EFEFEF',
    },
    inputField: {
        backgroundColor: '#FFFFFF',
        marginTop: 16,
        width: '100%',
        fontSize: 14,
        '& .MuiFormHelperText-root': {
            backgroundColor: '#F8F8F8',
            margin: 0,
            padding: 14,
            paddingTop: 3,
            paddingBottom: 0
        }
    },
    textFieldInput: {
        fontSize: 14
    },
    formContainer: {
        maxWidth: 600
    },
    slotPickTitle: {
        marginTop: 16,
        marginBottom: 16,
        color: '#646464',
        fontSize: 14,
    },
    slotContainer: {
        backgroundColor: '#FFF',
        width: '100%',
        borderRadius: 8
    },
    foodContainer: {
        backgroundColor: '#FFF',
        width: '100%',
        borderRadius: 8,
        marginBottom: 16
    },
    slotInner: {
        padding: 16,
    },
    slotTitle: {
        fontWeight: 'bold',
        color: "#454545",
        fontSize: 12,
        marginBottom: 8
    },
    slotInfo: {
        fontSize: 12,
        marginBottom:0
    },
    selectedSlotContainer: {
        background: '#1976d2',

    },
    slotTitleSelected: {
        fontWeight: 'bold',
        color: "#FFF",
        fontSize: 12,
        marginBottom: 8
    },
    slotInfoSelected: {
        fontSize: 12,
        marginBottom:0,
        color: '#FFF'
    },
    foodSectionHeader: {
        padding: "12px 16px",
        background: '#1976d2',
        borderBottom: '1px solid #ccc'
    },
    foodSectionTitle: {
        fontSize: 14,
        color: "#FFF"
    },
    foodSectionItem: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 24,
        paddingBottom: 24,
        width: '33%',
        background: '#FFF'
    },
    parayanaSectionItem: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 24,
        paddingBottom: 24,
        width: '24.7%',
        background: '#FFF',
        cursor: 'pointer',
        borderBottom: '1px solid #ccc'
    },
    foodItemLabel: {
        textAlign: 'center',
        color: '#565656',
        fontSize: 12
    },
    foodItemInfo: {
        textAlign: 'center',
        color: '#565656',
        fontSize: 10,
        marginTop: 8,
        fontWeight: 200
    },
    foodSelectedItem: {
        background: '#1976d2',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 24,
        paddingBottom: 24,
        width: '33.16%',
        [theme.breakpoints.down('sm')]: {
            width: '33.09%'
        }
    },
    parayanaSelectedItem: {
        background: '#1976d2',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 24,
        paddingBottom: 24,
        width: '24.7%',
        cursor: 'pointer'
    },
    foodSelectedLabel: {
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 200,
        color: '#FFF'
    },
    foodSelectedInfo: {
        textAlign: 'center',
        color: '#FFF',
        fontSize: 10,
        marginTop: 8,
        fontWeight: 200
    },
    summaryButton: {
        background: "#FF7377",
        color: "#FFF",
        textTransform: 'capitalize'
    }
});

export default styles;