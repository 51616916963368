export const routes = Object.freeze({
    login: '/login.php',
    getGothrams: '/getGothrams.php',
    getMember:'/getMemberData.php',
    generateOTP: '/generateOTP.php',
    sendSMSOTP: '/sendSMSOTP.php',
    generateOTPNonMember: '/generateOTPNonMember.php',
    sendSMSOTPNonMember: '/sendSMSOTPNonMember.php',
    verifyOTPNonMember: '/verifyOTPNonMember.php',
    loginAsNonMember: '/loginAsNonMember.php',
    getRegistration: '/getRegistration.php',
    getTags: '/getTags.php',
    submitRegistration: '/submitRegistration.php',
    sendEmailConfirmation: '/sendConfirmationEmail.php',
    sendWhatsappConfirmation: '/sendWhatsappConfirmation.php',
    deleteParticipant: '/deleteParticipant.php',
    deleteRegistration: '/deleteRegistration.php',
    sendEmailConfirmation: '/sendConfirmationEmail.php',
    getRegistrationByBarcode: '/getRegistrationByBarcode.php',
    submitSpotRegistration: '/submitSpotRegistration.php',
    getActiveEvents: '/getActiveEvents.php'
});

export const imageURL = 'https://master.sandhyavandanam.org/pages/getImageByID.php?size=100&id=';
export const apiURL = 'https://apis.sandhyavandanam.org/shatakoti';