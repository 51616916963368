import { setBusy, setToast } from "../components/Common/slice";
import { apiURL } from './constants';

export const sendGET = (route, dispatch, headers, silent=false) => {
    route = apiURL + route;
    dispatch(setBusy({ busy: true }));
    const requestOptions = {
        method: 'GET',
        headers: headers || {'Content-Type': 'application/json'},
    };    
    return fetch(route, requestOptions).then(async(response) => {
        dispatch(setBusy({ busy: false }));
        if (response.ok) {
            let data = await response.json();
            if (data.status) {
                return data;
            } else {
                if (!silent) {
                    dispatch(setToast({ message: data.message, type: 'error' }));
                }
                return data;
            }
        }
        throw response;
    });
}

export const sendPOST = (route, payload, dispatch, headers, silent=true) => {
    route = apiURL + route;
    dispatch(setBusy({ busy: true }));
    const requestOptions = {
        method: 'POST',
        headers: headers || {'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
    };
    return fetch(route, requestOptions).then(async(response) => {
        dispatch(setBusy({ busy: false }));
        if (response.ok) {
            let data = await response.json();
            if (data.status) {
                return data;
            } else {
                dispatch(setToast({ message: data.message, type: 'error' }));
                return data;
            }
        }
        throw response;
    });
}