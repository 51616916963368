import React from 'react';
import SimpleSnackbar from '../Common/SimpleSnackbar';
import ForgotPasswordView from './ForgotPasswordView';
import LoadingView from '../Common/LoadingView';
import { useSelector } from 'react-redux';
import { removeToast } from '../Common/slice';
import { Box } from '@mui/material';

const ForgotPasswordContainer = () => {
    const { toast, busy } = useSelector((state) => state.app);

    return (
        <>
            <Box height="1px">
                <LoadingView currentlySending={busy} />
            </Box>
            {
                toast.message && (
                <SimpleSnackbar 
                    type={toast.type} 
                    message={toast.message} 
                    removeToast={removeToast} 
                />
                )
            }
            <ForgotPasswordView />
        </>
    );
};

export default ForgotPasswordContainer;