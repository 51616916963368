import React, { useEffect, useState } from 'react';
import useClasses from '../../hooks/useClasses';
import { Box, Typography, MobileStepper, Paper, Stepper, Step, StepLabel, useTheme, useMediaQuery, Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MemberDetailsStep from './MemberDetailsStep';
import ParticipantsStep from './ParticipantsStep' ;
import RegistrationCompleteStep from './RegistrationCompleteStep';
import styles from './Styles';
import { useDispatch, useSelector } from 'react-redux';
import { sendGET, sendPOST } from "../../api";
import { routes as apiRoutes } from '../../api/constants';
import { modify, destroy as destroyRegistration } from './slice';
import isEmail from 'validator/lib/isEmail';
import { setGothrams, setSlots, setFoods, setParayana, destroy as destroyMetadata } from './metadata';
import { setToast } from '../Common/slice';
import DeleteIcon from '@mui/icons-material/Delete';
import { destroy } from '../Login/slice';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../router/constants';

const RegisterView = () => {
    const classes = useClasses(styles);
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const session = useSelector((state)=>state.session);
    const register = useSelector((state)=>state.register);
    const navigate = useNavigate();
    const content = React.useRef();
    const [steps, setSteps] = useState([
        {
            key: 'enrolment',
            label: 'Enrollment Details',
            desc: 'Please provide enrollment details below.',
            data: null,
        },
        {
            key: 'participant',
            label: 'Primary Participant',
            desc: 'Please provide / update your personal details below and click NEXT',
            data: 0,
        },
        {
            key: 'complete',
            label: 'Registration Status  🙏',
            desc: '',
            data: null,
        }
    ]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const isEmpty = (value) => {
        return value === null || value === undefined || value === "";
    }

    const handleClose = () => {
        setOpen(false);
    };

    const scrollToTop = () => {
        content.current.scroll({
          top: 0,
          behavior: "smooth"
        });
      };

    const handleNext = () => {
        if (activeStep == 0 && !isEmpty(register.arrival_date) && !isEmpty(register.depart_date) && !isEmpty(register.contact) && !isEmpty(register.email) && isEmail(register.email)) {
            setActiveStep(activeStep + 1);
            scrollToTop();
        } else if (
            activeStep > 0 && 
            activeStep < steps.length - 1 && 
            !isEmpty(register.participants[steps[activeStep].data].type) && 
            !isEmpty(register.participants[steps[activeStep].data].first_name) && 
            !isEmpty(register.participants[steps[activeStep].data].last_name) && 
            !isEmpty(register.participants[steps[activeStep].data].gothram) && 
            !isEmpty(register.participants[steps[activeStep].data].gender) &&
            !isEmpty(register.participants[steps[activeStep].data].age) && 
            (
                (
                    session.is_member && (
                        (
                            (
                                register.participants[steps[activeStep].data].type === "MEMBER" || 
                                register.participants[steps[activeStep].data].type === "JAPATHA"
                            ) && !isEmpty(register.participants[steps[activeStep].data].member_id)
                        ) ||
                        (
                            register.participants[steps[activeStep].data].type === "VISITOR" &&
                            isEmpty(register.participants[steps[activeStep].data].member_id)
                        )
                    )
                ) || (
                    !session.is_member && (
                        register.participants[steps[activeStep].data].type === "VISITOR" &&
                        (
                            activeStep === 1 ? !isEmpty(register.participants[steps[activeStep].data].member_id) :
                            isEmpty(register.participants[steps[activeStep].data].member_id)
                        )
                    )
                )
            )
        ) {
            setActiveStep(activeStep + 1);
            scrollToTop();
        } else {
            console.log('Active Step', activeStep);
            console.log('Type', register.participants[steps[activeStep].data].type);
            console.log('First Name', register.participants[steps[activeStep].data].first_name);
            console.log('Last Name', register.participants[steps[activeStep].data].last_name);
            console.log('Gothram', register.participants[steps[activeStep].data].gothram);
            console.log('Gender', register.participants[steps[activeStep].data].gender);
            console.log('Age', register.participants[steps[activeStep].data].age);
            console.log('Member ID', register.participants[steps[activeStep].data].member_id);
            dispatch(setToast({ message: 'Please fill all the required fields to proceed.', type: 'error' }));
        }
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1);
        scrollToTop();
    }

    useEffect(() => {
        if (session && session.id !== undefined) {
            sendGET(
                apiRoutes.getRegistration + `?id=${session.id}&event=${session.event.event_id}`,
                dispatch,
                {'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}`},
                true
            ).then(data => {
                console.log("Registration", data)
                if (data.status) {
                    dispatch(modify({
                        registration_id: data.registration_id,
                        event_id: data.event_id,
                        contact: data.contact,
                        name: data.name,
                        email: data.email,
                        poc_id: data.poc_id,
                        no_of_participants: data.participants.length - 1,
                        arrival_date: data.arrival_date,
                        depart_date: data.depart_date,
                        barcode: data.barcode,
                        participants: data.participants,
                        registered_timestamp: data.registered_timestamp,
                    }));
                } else {
                    dispatch(modify({
                        registration_id: null,
                        event_id: session.event.event_id,
                        contact: session.contact,
                        email: session.email,
                        poc_id: session.id,
                        name: session.firstName + " " + session.lastName,
                        no_of_participants: 0,
                        arrival_date: session.event.start_date,
                        depart_date: session.event.end_date,
                        barcode: "",
                        participants: [
                            {
                                participant_id: null,
                                member_id: session.id,
                                first_name: session.firstName,
                                last_name: session.lastName,
                                age: "",
                                gender: "",
                                type: "",
                                gothram: "",
                                slots: [],
                                food: [],
                                is_srisevak: "NO"
                            }
                        ],
                        registered_timestamp: null,
                    }));
                }
            })     
            sendGET(
                apiRoutes.getGothrams,
                dispatch,
                { 'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}`  }
            ).then(data => {
                if (data.status) {
                    dispatch(setGothrams({ gothrams: data.gothrams }));
                }
            });
            sendGET(
                apiRoutes.getTags,
                dispatch,
                { 'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}`  }
            ).then(data => {
                console.log(data);
                if (data.status) {
                    dispatch(setSlots({ slots: data.slots }));
                    dispatch(setFoods({ food: data.food }));
                    dispatch(setParayana({ parayana: data.parayana }));
                }
            });       
        }        
    }, []);

    const handleOpen = () => {
        setOpen(true);
    }

    const deleteRegistration = () => {
        sendPOST(
            apiRoutes.deleteRegistration,
            { registration_id: register.registration_id },
            dispatch,
            { 'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}`  }
        ).then(data => {
            console.log(data);
            if (data.status) {
                dispatch(destroy());
                dispatch(destroyRegistration());
                dispatch(destroyMetadata());
                dispatch(setToast({ message: 'Registration deleted successfully. Logging you out...', type: 'success' }));
                navigate(routes.LOGIN);
            }
        });   
    }

    const deleteParticipant = (index) => {
        sendPOST(
            apiRoutes.deleteParticipant,
            { registration_id: register.registration_id, participant_id: register.participants[index].participant_id },
            dispatch,
            { 'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}`  }
        ).then(data => {
            console.log(data);
            if (data.status) {
                setActiveStep(0);
                let participants = [...register.participants];
                participants.splice(index, 1);
                dispatch(modify({
                    registration_id: register.registration_id,
                    event_id: register.event_id,
                    contact: register.contact,
                    name: register.name,
                    email: register.email,
                    poc_id: register.poc_id,
                    no_of_participants: register.no_of_participants - 1,
                    arrival_date: register.arrival_date,
                    depart_date: register.depart_date,
                    barcode: register.barcode,
                    participants: participants,
                    registered_timestamp: register.registered_timestamp,
                }));                
                setOpen(false);
            }
        });
    }

    return (
        <Box p={isMobile ? 0 : 2} className={classes.boundingBox}>
            {
                !isMobile ? (
                    <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
                        {
                            steps.map((step) => (
                                <Step key={step.label}>
                                    <StepLabel>{step.label}</StepLabel>
                                </Step>
                            ))
                        }
                    </Stepper>
                ) : (
                    <Paper 
                        square 
                        elevation={0}
                        className={classes.mobileLabel}
                    >
                        <Typography flexGrow={1}>{steps[activeStep].label}</Typography>
                        {   steps[activeStep].data != null && register.hasOwnProperty('registration_id') && register.registration_id != null &&
                            <Button variant="outlined" startIcon={<DeleteIcon />} color='error' sx={{ mr: 2 }} onClick={handleOpen}>
                                Delete
                            </Button>
                        }                        
                    </Paper>
                )
            }
            <Box className={classes.stepContent} ref={content}>
                {
                    !isMobile && 
                    register.hasOwnProperty('registration_id') && 
                    register.registration_id != null && 
                    steps[activeStep].data != null && 
                    register.participants[steps[activeStep].data] != undefined && 
                    register.participants[steps[activeStep].data].hasOwnProperty('participant_id') &&
                    register.participants[steps[activeStep].data].participant_id != null &&
                    register.participants[steps[activeStep].data].participant_id != "" &&
                    <>
                        <Typography variant='body2' component='div' className={classes.stepDesc}>Actions:</Typography>
                        <Button variant="outlined" startIcon={<DeleteIcon />} color='error' sx={{ my: 1 }} onClick={handleOpen}>
                            Delete { steps[activeStep].data == 0 ? 'Registration' : 'Participant' }
                        </Button>
                    </>
                }
                {
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Delete { steps[activeStep].data == 0 ? "Registration" : "Participant" }
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to proceed with the delete action? { steps[activeStep].data == 0 ? "By proceeding your complete registration will be deleted including your co-participants and slots. Once deleted you will be logged out automatically." : "" }
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose}>Disagree</Button>
                        <Button onClick={() => { steps[activeStep].data == 0 ? deleteRegistration() : deleteParticipant(steps[activeStep].data) }} autoFocus>
                            Agree
                        </Button>
                        </DialogActions>
                    </Dialog>
                }                
                <Typography variant='body2' component='div' className={classes.stepDesc}>{ steps[activeStep].desc }</Typography>
                {
                    {
                        'enrolment' : <MemberDetailsStep setSteps={setSteps} />,
                        'participant' : <ParticipantsStep index={steps[activeStep].data}/>,
                        'complete' : <RegistrationCompleteStep/>
                    }[steps[activeStep].key]
                }             
            </Box>
            {
                isMobile ? (
                    <MobileStepper 
                        steps={steps.length} 
                        position="static" 
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === steps.length-1}
                            >
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                            </Button>
                        }
                    />
                ) : (
                    <React.Fragment>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pr: 2, pl: 2 }}>
                            <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                            >
                            Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {
                                activeStep !== steps.length - 1 &&
                                <Button onClick={handleNext}>
                                    Next
                                </Button>
                            }                        
                        </Box>
                    </React.Fragment>
                )
            }
        </Box>
    );
};

export default RegisterView;