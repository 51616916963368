import { createSlice } from "@reduxjs/toolkit";

export const register = createSlice({
    name: 'register',
    initialState: {
        registration_id: null,
        contact: null,
        event_id: null,
        email: null,
        poc_id: null,
        name: null,
        no_of_participants: null,
        arrival_date: null,
        depart_date: null,
        barcode: null,
        participants: [],
        registered_timestamp: null,
    },
    reducers: {
        modify: (state, action) => {
            state.registration_id = action.payload.registration_id;
            state.contact = action.payload.contact;
            state.event_id = action.payload.event_id;
            state.email = action.payload.email;
            state.name = action.payload.name;
            state.poc_id = action.payload.poc_id;
            state.no_of_participants = action.payload.no_of_participants;
            state.arrival_date = action.payload.arrival_date;
            state.depart_date = action.payload.depart_date;
            state.barcode = action.payload.barcode;
            state.participants = action.payload.participants;
            state.registered_timestamp = action.payload.registered_timestamp;
        },
        destroy: (state) => {
            state.registration_id = null;
            state.contact = null;
            state.event_id = null;
            state.poc_id = null;
            state.email = null;
            state.name = null;
            state.no_of_participants = null;
            state.arrival_date = null;
            state.depart_date = null;
            state.barcode = null;
            state.participants = null;
            state.registered_timestamp = null;
        },
        setContact: (state, action) => {
            state.contact = action.payload.contact
        },
        setEmail: (state, action) => {
            state.email = action.payload.email;
        },
        setNoOfParticipants: (state, action) => {
            state.no_of_participants = action.payload.no_of_participants;
        },
        setArrivalDate: (state, action) => {
            state.arrival_date = action.payload.arrival_date;
        },
        setDepartDate: (state, action) => {
            state.depart_date = action.payload.depart_date;
        },
        setParticipants: (state, action) => {
            state.participants = action.payload.participants;
        },
        updateParticipant: (state, action) => {
            state.participants[action.payload.index] = action.payload.participant;
        }
    }
});

export const { 
    modify, 
    destroy, 
    setContact, 
    setEmail, 
    setNoOfParticipants, 
    setArrivalDate, 
    setDepartDate, 
    setParticipants, 
    updateParticipant,
    updateSlot
} = register.actions;
export default register.reducer;