import React from 'react';
import styles from './Styles';
import useClasses from '../../hooks/useClasses';
import { Tabs, Tab, Divider, Button } from '@mui/material';
import { destroy } from '../Login/slice';
import { destroy as destroyRegistration } from '../Registration/slice';
import { destroy as destroyMetadata } from '../Registration/metadata';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../router/constants';

const NavigationMenu = () => {
    const classes = useClasses(styles);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logOut = () => {  
        dispatch(destroy());
        dispatch(destroyRegistration());
        dispatch(destroyMetadata());
        navigate(routes.LOGIN);
    }
    return (
        <>
            {/* <Tabs value={"one"} textColor="primary" classes={{ root: classes.navRoot }}>
                <Tab value="one" label="About" classes={{ root: classes.navItem, selected: classes.navItemSelected }} />
                <Tab value="two" label="Team" classes={{ root: classes.navItem, selected: classes.navItemSelected }} />
                <Tab value="three" label="Gallery" classes={{ root: classes.navItem, selected: classes.navItemSelected }} />
            </Tabs>
            <Divider orientation="vertical" flexItem variant="middle" classes={{ root: classes.divider }}/> */}
            <Button variant='filled' className={classes.registerBtn} onClick={logOut}>Logout</Button>
            {/* <Button variant='filled' className={classes.registerBtn}>Register as Japatha</Button>
            <Button variant='filled' className={classes.donateBtn}>Donate</Button> */}
        </>
    );
};

export default NavigationMenu;