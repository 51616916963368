import React from 'react';
import SimpleSnackbar from '../Common/SimpleSnackbar';
import LoadingView from '../Common/LoadingView';
import { Box } from '@mui/material';
import Header from '../Header';
import { useSelector } from 'react-redux';
import SpotView from './SpotView';
import { removeToast } from '../Common/slice';

const Spot = () => {
    const { toast, busy } = useSelector((state) => state.app);
    return (
        <>
            <Header/>
            <Box height="1px">
                <LoadingView currentlySending={busy} />
            </Box>
            {
                toast.message && (
                <SimpleSnackbar 
                    type={toast.type} 
                    message={toast.message} 
                    removeToast={removeToast} 
                />
                )
            }
            <SpotView />
        </>
    );
};

export default Spot;