import { theme } from '../../theme';
const styles = (theme) => ({
    topHeader: {
        backgroundColor: '#772805',
        boxShadow: 'none',
    },
    topToolbar: {
        minHeight: 40,
        paddingLeft: 64,
        paddingRight: 64,
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 32,
            paddingRight: 32
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 16,
            paddingRight: 16
        }
    },
    appBar: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
    },
    toolbar: {
        minHeight: 70,
        paddingLeft: 64,
        paddingRight: 64,
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 32,
            paddingRight: 32
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 16,
            paddingRight: 16
        },
    },
    appName: {
        color: '#000000',
        fontSize: 16,
        fontWeight: 700,
        [theme.breakpoints.down('lg')]: {
            fontSize: 14
        }
    },
    headerTag: {
        color: '#FFFFFF',
        fontSize: 14,
        paddingLeft: 12,
        [theme.breakpoints.down('lg')]: {
            fontSize: 12
        }
    },
    logo: {
        height: 50,
        marginRight: 8
    },
    socialIcons: {
        color: '#FFFFFF',
        marginRight: 16,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    navRoot: {
        '& .MuiTabs-indicator': {
            display: 'none',
        },
    },
    navItem: {
        color: '#000000',
        fontSize: 14,
        fontWeight: 500,
        textTransform: 'capitalize',
        minWidth: 10,
        '&:hover': {
            color: '#772805'
        },
    },
    navItemSelected: {
        fontWeight: 'bold'
    },
    divider: {
        marginTop: 16,
        marginBottom: 16,
        marginLeft: 8,
    },
    registerBtn: {
        backgroundColor: '#772805',
        color: '#FFFFFF',
        fontSize: 12,
        textTransform: 'capitalize',
        marginLeft: 16,
        '&:hover': {
            backgroundColor: '#772805',
            cursor: 'pointer'
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: 16
        }
    },
    donateBtn: {
        backgroundColor: '#FFC107',
        color: '#000000',
        fontSize: 12,
        textTransform: 'capitalize',
        marginLeft: 16,
        '&:hover': {
            backgroundColor: '#FFC107',
            cursor: 'pointer'
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: 16
        }
    },
    mobileMenu: {
        width: `calc(100% + 16px)`,
        background: '#F8F8F8',
        minHeight: 150,
        position: 'absolute',
        top: 71,
        left: 0,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
        transform: 'scaleY(0)',
        transformOrigin: 'top center',
        transition: 'transform 250ms ease-in-out'
    },
    mobileMenuOpen: {
        width: '100%',
        background: '#F8F8F8',
        minHeight: 150,
        position: 'absolute',
        top: 71,
        left: 0,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
        transform: 'scaleY(100%)',
        transformOrigin: 'top center',
        transition: 'transform 250ms ease-in-out'
    },
    menuItem: {
        paddingLeft: 24,
        '& a': {
            fontSize: 14,
            fontWeight: 500,
            textDecoration: 'none',
            color: '#000000'
        },
        '& a.active': {
            fontWeight: 700,
            color: '#772805'
        }
    },
});

export default styles;