import React, { useEffect, useState } from 'react';
import useClasses from '../../hooks/useClasses';
import styles from './Styles';
import { 
    useTheme, 
    useMediaQuery, 
    Box, 
    TextField, 
    InputAdornment, 
    Grid, MenuItem, 
    Avatar, 
    Autocomplete, 
    IconButton, 
    Divider, 
    Typography, 
    Button, 
    RadioGroup, 
    FormControlLabel,
    Radio
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { sendGET, sendPOST } from '../../api';
import { routes as apiRoutes, routes } from '../../api/constants';
import { imageURL } from '../../api/constants';
import SearchIcon from '@mui/icons-material/Search';
import { setGothrams, setSlots, setFoods, setParayana } from '../Registration/metadata';
import Slot from '../Registration/Slot';
import ParayanaSlot from '../Registration/ParayanaSlot';
import isEmail from 'validator/lib/isEmail';
import MuiPhoneNumber from "material-ui-phone-number";
import { setToast } from "../Common/slice";
import { setEvent } from "../Login/slice";

const SpotView = () => {
    const classes = useClasses(styles);
    const dispatch = useDispatch();
    const [member_id, setMemberID] = useState(null);
    const [type, setType] = useState("VISITOR");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [contact, setContact] = useState("+91");
    const [email, setEmail] = useState("");
    const [gothram, setGothram] = useState("AATREYASA");
    const [gothramOthers, setGothramOthers] = useState("");
    const [gender, setGender] = useState("M");
    const [age, setAge] = useState(null);
    const [slots, setTags] = useState([]);
    const [events, setEvents] = useState([]);
    const [show_parayana, setShowParayana] = React.useState(false);
    const [participant, setParticipant] = useState(
        {
            member_id: null,
            type: "VISITOR",
            first_name: "",
            last_name: "",
            contact: "",
            email: "",
            gothram: "",
            gender: "M",
            age: null,
            slots: []
        }
    );    
    const metadata = useSelector((state) => state.metadata);
    const session = useSelector((state) => state.session);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const getMemberDetails = (id) => {
        if (id == "" || id == null) {
            return;
        }
        sendGET(
            apiRoutes.getMember + `?id=${id}`,
            dispatch,
            { 'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}` }
        ).then(data => {
            if (data.status) {
                setMemberID(data.MemberID);
                setType(data.JapathaEnrollment === "1" ? "JAPATHA" : "MEMBER");
                setFirstName(data.FirstName);
                setLastName(data.LastName);
                setGothram(data.Gothram);
                setGender(data.Gender);
                setAge(data.Age);
                setContact('+' + data.CountryCode + data.ContactNumber);
                setEmail(data.Email);
            }
        });
    };

    const getActiveEvents = () => {
        sendGET(
            apiRoutes.getActiveEvents,
            dispatch
        ).then(data => {
            if (data.status) {
                setEvents(data.events);
                setEvent(data.events[0].event_id)
            }
        })
    };

    React.useEffect(getActiveEvents, []);

    useEffect(() => {
        const new_participant = {
            member_id: member_id,
            type: type,
            first_name: first_name,
            last_name: last_name,
            gothram: gothram === "OTHERS" ? gothramOthers : gothram,
            gender: gender,
            age: age,
            contact: contact,
            email: email,
            slots: slots
        };
        setParticipant(new_participant);
    }, [member_id, type, first_name, last_name, gothram, gothramOthers, gender, age, slots, contact, email]);

    useEffect(() => {
        sendGET(
            apiRoutes.getGothrams,
            dispatch,
            { 'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}`  }
        ).then(data => {
            if (data.status) {
                dispatch(setGothrams({ gothrams: data.gothrams }));
            }
        });
        sendGET(
            apiRoutes.getTags,
            dispatch,
            { 'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}`  }
        ).then(data => {
            console.log(data);
            if (data.status) {
                dispatch(setSlots({ slots: data.slots }));
                dispatch(setFoods({ food: data.food }));
                dispatch(setParayana({ parayana: data.parayana }));
            }
        });
    }, []);

    const addSlot = (tag_id) => {
        let slots_temp = [
            ...slots
        ];
        if (slots_temp.indexOf(tag_id) <= -1) {
            slots_temp.push(tag_id);
            setTags(slots_temp);
        }
    };

    const removeSlot = (tag_id) => {
        let slots_temp = [
            ...slots
        ];
        let pos = slots_temp.indexOf(tag_id);
        if (pos > -1) {
            slots_temp.splice(pos, 1);
        }
        setTags(slots_temp);
    };

    React.useEffect(() => {
        const slots = [...participant.slots];
        if (!show_parayana) {
            Object.keys(metadata.parayana).map((date) => {
                metadata.parayana[date].map((parayana) => {
                    if (slots.includes(parayana.tag_id)) {
                        slots.splice(slots.indexOf(parayana.tag_id), 1)
                    }
                })
            })
        }
        setTags(slots);
    }, [show_parayana])

    const submitDisabled = () => {
        if ((participant.type === "MEMBER" || participant.type === "JAPATHA") && (participant.member_id === null || participant.member_id === "")) {
            return true;
        } else if (
            participant.first_name === "" || 
            participant.first_name === null || 
            participant.last_name === "" || 
            participant.last_name === null || 
            participant.gothram === null || 
            participant.gothram === "" ||
            participant.contact === "" ||
            participant.contact === null ||
            participant.email === "" ||
            participant.email === null ||
            !isEmail(participant.email)
        ) {
            return true;
        }
        return false;
    };

    const submit = () => {
        let today = new Date();
        const offset = today.getTimezoneOffset();
        today = new Date(today.getTime() - (offset*60*1000))
        const payload = {
            ...participant,
            event_id: session.event.event_id,
            arrival_date: today.toISOString().split('T')[0],
            depart_date: today.toISOString().split('T')[0],
            poc_id: member_id === null || member_id === "" ? uuidv4() : member_id,
        };
        console.log(payload);
        sendPOST(
            routes.submitSpotRegistration,
            payload,
            dispatch,
            { 'Content-Type': 'application/json', 'Authorization': `Bearer ${session.token}` },
            false
        ).then(data => {
            console.log(data);
            if (data.status) {
                dispatch(setToast({ message: data.message, type: 'success' }));
                setMemberID(null);
                setType("VISITOR");
                setFirstName("");
                setLastName("");
                setGothram("AATREYASA");
                setGender("M");
                setAge(null);
                setContact("+91");
                setEmail("");
                setTags([]);
                setShowParayana(false)
            }
        });
    }

    const setSelectedEvent = () => {
        const current_event = events.filter(e => e.event_id == event);
        setEvent(current_event[0]);
    }

    const setParticipantType = (e) => { 
        setType(e.target.value); 
        if (e.target.value === "VISITOR") {
            setMemberID(null)
        }
    }

    return (
        <Box p={ isMobile ? 2 : 4} mb={2} className={classes.formContainer}>
            <Typography variant='h5' component={"div"} className={classes.spotTitle}>Spot Registration</Typography>
            <Typography variant='body2' component={"p"} sx={{ marginBottom: 2, color: '#666' }}>Please enter all the required fields. Spot registration is only required for the participants who have not already registered.</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        select
                        label="Event"
                        autoFocus={true}
                        name="event"
                        value={session.event !== undefined ? session.event.event_id : events[0].event_id}
                        className={classes.inputField}
                        InputProps={{
                            required: true,
                            classes: { input: classes.textFieldInput },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setSelectedEvent(e.target.value)}
                    >
                        {
                            events.map((event) => (
                                <MenuItem key={event.event_id} value={event.event_id}>{ event.event_name } - { event.place }</MenuItem>
                            ))
                        }
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        required
                        label="Type"
                        autoFocus={true}
                        value={type}
                        className={classes.inputField}
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={setParticipantType}
                    >
                        <MenuItem key={"JAPATHA"} value={"JAPATHA"}>Japatha</MenuItem>
                        <MenuItem key={"MEMBER"} value={"MEMBER"}>Member</MenuItem>
                        <MenuItem key={"VISITOR"} value={"VISITOR"}>Visitor</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Member ID"
                        type="number"
                        value={type != "VISITOR" ? member_id : ""}
                        disabled={type == "VISITOR" ? true : false}
                        helperText={type !== "VISITOR" ? "Click the search button after entering Member ID to auto fetch details" : ''}
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Avatar
                                        alt="Member Image"
                                        src={type != "VISITOR" ? (imageURL + (member_id !== null ? member_id : "2427")): ""}
                                        sx={{ width: 24, height: 24 }}
                                    />
                                </InputAdornment>
                            ),
                            endAdornment: type !== "VISITOR" && (
                                <IconButton onClick={() => getMemberDetails(member_id)}>
                                    <SearchIcon />
                                </IconButton>
                            )
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        className={classes.inputField}
                        onBlur={(e) => getMemberDetails(e.target.value)}
                        onChange={(e) => setMemberID(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        label="Surname"
                        required
                        value={first_name}
                        className={classes.inputField}
                        variant='outlined'
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        label="Given Name"
                        required
                        value={last_name}
                        className={classes.inputField}
                        variant='outlined'
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </Grid>                
                <Grid item xs={6}>
                    <MuiPhoneNumber
                        label="Contact Number"
                        required
                        fullWidth
                        value={contact}
                        defaultCountry={"in"}
                        variant='outlined'
                        InputProps={{
                            classes: { input: classes.textFieldInput }
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className={classes.inputField}
                        onChange={(e) => setContact(e) }
                    />    
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Email"
                        error={email !== "" && email !== undefined && email !== null && !isEmail(email)}
                        required
                        type="email"
                        value={email}
                        helperText={email !== "" && email !== undefined && email !== null && !isEmail(email) ? "Invalid email" : ''}
                        className={classes.inputField}
                        variant='outlined'
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setEmail(e.target.value) }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        required
                        label="Gender"
                        value={gender}
                        className={classes.inputField}
                        variant='outlined'
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setGender(e.target.value)}
                        disabled={type == "VISITOR" ? false : ( gender != "" ? true : false )}
                    >
                        <MenuItem key={"M"} value={"M"}>Male</MenuItem>
                        <MenuItem key={"F"} value={"F"}>Female</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Age"
                        type="number"
                        value={age}
                        className={classes.inputField}
                        variant='outlined'
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setAge(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        disablePortal
                        required
                        options={metadata.gothrams ? metadata.gothrams : null}
                        InputProps={{
                            classes: { input: classes.textFieldInput },
                        }}
                        className={classes.inputField}
                        value={gothram}                        
                        renderInput={(params) => <TextField {...params} label="Gothram"
                            helperText="Select OTHERS if your gothram is not in the dropdown"
                            InputLabelProps={{
                                shrink: true,
                            }} />}
                        onChange={(e) => setGothram(e.target.innerHTML)}
                    />
                </Grid>
                {
                    gothram == 'OTHERS' &&
                    <Grid item xs={6}>
                        <TextField
                            label="Please specify if others"
                            type="text"
                            value={gothramOthers}
                            className={classes.inputField}
                            variant='outlined'
                            InputProps={{
                                classes: { input: classes.textFieldInput },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setGothramOthers(e.target.value)}
                        />
                    </Grid>
                }                
            </Grid>
            {
                type === "JAPATHA" && (
                    <>
                        <Divider sx={{ marginTop: 2 }} />
                        <Typography variant='body1' component="div" className={classes.slotPickTitle}>Choose upto 5 slots for {`${first_name} ${last_name}`} by clicking below.</Typography>
                        <Typography variant='body1' component="div" className={classes.slotPickTitle} style={{ color: 'red' }}>Note: Only those who have done japam more than 50,000 will be allowed to perform homam. In case your japa sankhya is less than 50,000 by the end of December 2022, your slot will be automatically cancelled.</Typography>
                        <Grid container spacing={1}>
                            {
                                metadata.slots.map((slot, idx) => (
                                    slot.max_tags - slot.count > 0 && slot.type === "HOMAM" && (<Grid item xs={6}>
                                        <Slot
                                            index={idx}
                                            tag_label={slot.tag_label}
                                            tag_id={slot.tag_id}
                                            removeSlot={(tag_id) => removeSlot(tag_id)}
                                            addSlot={(tag_id) => addSlot(tag_id)}
                                            remaining={slot.max_tags - slot.count}
                                            participant={participant}
                                        />
                                    </Grid>)
                                ))
                            }
                        </Grid>
                    </>
                )
            }
            {/* {
                gender === "F" && (
                    <>
                        <Divider sx={{ marginTop: 2 }} />
                        <Typography variant='body1' component="div" className={classes.slotPickTitle}>Choose archana slots for {`${first_name} ${last_name}`} by clicking below.</Typography>
                        <Grid container spacing={1}>
                            {
                                metadata.slots.map((slot, idx) => (
                                    slot.max_tags - slot.count > 0 && slot.type === "ARCHANA" && (<Grid item xs={6}>
                                        <Slot
                                            index={idx}
                                            tag_label={slot.tag_label}
                                            tag_id={slot.tag_id}
                                            removeSlot={(tag_id) => removeSlot(tag_id)}
                                            addSlot={(tag_id) => addSlot(tag_id)}
                                            remaining={slot.max_tags - slot.count}
                                            participant={participant}
                                        />
                                    </Grid>)
                                ))
                            }
                        </Grid>
                    </>
                )
            } */}
            <Typography variant='body1' component="div" className={classes.slotPickTitle}>Will <strong>{`${first_name} ${last_name}`}</strong> participate in Ramayana Parayana being conducted at Ayodhya?</Typography>
            <Grid container spacing={1}>
                <RadioGroup
                    value={show_parayana ? "YES": "NO"}
                    style={{ display: 'flex', flexDirection: 'row', marginLeft: 16 }}
                    onChange={(e, value) => setShowParayana(value === "YES" ? true : false)}
                >
                    <FormControlLabel value="YES" control={<Radio />} label="Yes" />
                    <FormControlLabel value="NO" control={<Radio />} label="No" />
                </RadioGroup>
            </Grid>
            {
                show_parayana && (<>
                    <Typography variant='body1' component="div" className={classes.slotPickTitle} style={{ marginBottom: 32 }}>Please select slots for Ramayana Parayanam below.</Typography>
                    <Grid container spacing={1}>
                        {
                            Object.keys(metadata.parayana).map((date) => (
                                <ParayanaSlot
                                    index={0}
                                    date={date}
                                    addSlot={addSlot}
                                    removeSlot={removeSlot}
                                    parayanas={metadata.parayana[date]}
                                    participant={participant}
                                />
                            ))
                        }
                    </Grid>
                </>)
            }
            <Button onClick={submit} sx={{ marginTop: 2 }} variant="contained" className={classes.submitBtn} disabled={submitDisabled()}>Submit</Button>
        </Box>
    );
};

export default SpotView;