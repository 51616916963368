import { theme } from '../../theme';

const styles = (theme) => ({
    detailsContainer: {
        padding: 32,
        paddingLeft: 64,
        [theme.breakpoints.down("sm")]: {
            padding: 24
        }
    },
    section: {
        marginBottom: 24
    },
    detailsHeading: {
        fontSize: 24,
        fontWeight: 600,
        color: '#646464',
        marginBottom: 8,
        [theme.breakpoints.down("sm")]: {
            fontSize: 22
        }
    },
    generic_text: {
        fontSize: 14,
        color: '#646464',
    },
    subHeading: {
        fontSize: 18,
        fontWeight: 600,
        color: "#646464",
        marginBottom: 8
    },
    listItemLabel: {
        fontSize: 14,        
    },
    listItem: {
        fontSize: 14,
        marginBottom: 8,
        fontWeight: 600
    },
    barcode: {        
        display: 'flex',
        marginTop: 8
    },
    participantHeading: {
        fontSize: 16,
        fontWeight: 600,
        color: '#646464',
        marginBottom: 8
    }
});

export default styles;